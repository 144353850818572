import React from 'react';
import { Layer, Box } from 'grommet';
import styles from './ErrorPopup.module.css';

class ErrorPopup extends React.Component {
    render() {
        return (
            <Layer style={{background: "none"}} onEsc={this.props.closePopup} onClickOutside={this.props.closePopup}>
                <Box className={styles.outerDiv}>
                    <Box className={styles.header}>
                        <div className={styles.headerText}>{this.props.title}</div>
                    </Box>
                    <div className={styles.message}>{this.props.message}</div>
                    <button className={styles.acceptButton} onClick={this.props.closePopup}>Cancel</button>
                </Box>
            </Layer>
        )
    }
}
export default ErrorPopup;