import React from 'react';
import styles from './Heart.module.css';
import {  Box} from 'grommet';
import Lottie from 'react-lottie';
import animationData from './heart.json';
import * as firebase from 'firebase/app';

import { db } from '../../index.js';
import ReactTooltip from 'react-tooltip';

// import { relativeTimeThreshold } from 'moment';

class Heart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            heart: this.props.heart,
            filled: "",
            empty: "",
        }
        this.changeFavorite = this.changeFavorite.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.heart !== this.props.heart) {
            this.setState ({
                heart: this.props.heart,
            });
        }
    }

    changeFavorite() {
        let clubUUID = this.props.clubUUID;
        var favorited = this.state.heart;
        this.setState(prevState=>({
            heart: !prevState.heart
        })); 
        if(this.props.changeHeart !== undefined) {
            this.props.changeHeart();
        }
        if (favorited) {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                const date = new Date();
                window.ga('send', 'event', 'Club Interaction', 'Unfavorited');
                db.collection('logged_events').add({
                    'category': 'Club Interaction',
                    'action': 'Unfavorited',
                    'time': date,
                    'user': idToken,
                    'club': this.props.clubUUID,
                    'url': window.location.href,
                });
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "unlike"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "leave",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.props.addToLocalMailingList("leave", clubUUID, firebase.auth().currentUser.uid, firebase.auth().currentUser.email);
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        else {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                const date = new Date();
                window.ga('send', 'event', 'Club Interaction', 'Favorited');
                db.collection('logged_events').add({
                    'category': 'Club Interaction',
                    'action': 'Favorited',
                    'time': date,
                    'user': idToken,
                    'club': this.props.clubUUID,
                    'url': window.location.href,
                });
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "like",
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "join",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.props.addToLocalMailingList("join", clubUUID, firebase.auth().currentUser.uid, firebase.auth().currentUser.email);
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }

        if (this.props.unfavorite !== undefined) {
            this.setState({
                heart: true,
            });
            this.props.unfavorite(this.props.clubUUID);
        }
    }

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: { 
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        var opacity = .25;
        if(this.props.changeHeart === undefined) {
            opacity = 1;
        }
        return(
            <div>
            <div data-tip data-for="heartTip">
                {
                    (this.state.heart)?
                    <Box onClick={this.changeFavorite}  id={styles.lottie} >
                        {/*<img onClick={this.changeFavorite} id={styles.heart} alt = "seleceted" src={this.props.filled}/> */}
                        <Lottie options={defaultOptions} height={"115px"} width={"115px"} id={styles.lottie} />
                    </Box>
                    :
                    <Box style = {{ opacity: opacity}}>
                        <img onClick={this.changeFavorite} id={styles.heart}  alt = "unselected" src={this.props.empty}/>
                    </Box>
                }
            </div>
            <ReactTooltip id="heartTip" className={styles.heartTip} place="top" effect="solid" delayShow={1000}>Like & Subscribe</ReactTooltip>
            </div>
        );
    }
    
}

export default Heart;