import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import AppTwo from './AppTwo'
import { BrowserRouter as Router } from 'react-router-dom';
import './fonts.css';
import './globalStyle.css';

import * as firebase from 'firebase/app'
import "firebase/analytics"
import "firebase/auth"
import "firebase/storage"
import "firebase/firestore"

var firebaseConfig = {
  apiKey: "AIzaSyBq1RpuDup9WsbGAB1alxnAdoDg6Fkv5k8",
  authDomain: "uniflow-events.firebaseapp.com",
  databaseURL: "https://uniflow-events.firebaseio.com",
  projectId: "uniflow-events",
  storageBucket: "uniflow-events.appspot.com",
  messagingSenderId: "636223778307",
  appId: "1:636223778307:web:53f08dc408fc243512e836",
  measurementId: "G-3E8SXST9FY",
};
if (window.location.hostname === "psusmeal.uniflowcampus.com") {
    firebaseConfig = {
        apiKey: "AIzaSyAa-QBcz0qtnZF4lqge8capRI1HVvva3RQ",
        authDomain: "uniflow-events-psu.firebaseapp.com",
        databaseURL: "https://uniflow-events-psu.firebaseio.com",
        projectId: "uniflow-events-psu",
        storageBucket: "uniflow-events-psu.appspot.com",
        messagingSenderId: "757743954572",
        appId: "1:757743954572:web:7e54c13c2eb1eba44e29a9",
        measurementId: "G-KH2Q771Z7V"
    };
}
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export var db = firebase.firestore();

function setUserAgent(window, userAgent) {
    // Works on Firefox, Chrome, Opera and IE9+
    if (navigator.__defineGetter__) {
        navigator.__defineGetter__('userAgent', function () {
            return userAgent;
        });
    } 
    else if (Object.defineProperty) {
        Object.defineProperty(navigator, 'userAgent', {
            get: function () {
                return userAgent;
            }
        });
    }
    // Works on Safari
    if (window.navigator.userAgent !== userAgent) {
        var userAgentProp = {
            get: function () {
                return userAgent;
            }
        };
        try {
            Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
        } catch (e) {
            window.navigator = Object.create(navigator, {
                userAgent: userAgentProp
            });
        }
    }
}
//setUserAgent(window, "Mozilla/5.0 Google");

ReactDOM.render((
  <Router>
    <App fb_storage={storage} db={db}/>
  </Router>),
  document.getElementById('root') || document.createElement('div')
);

