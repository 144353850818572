import React from 'react';
import styles from './Reactions.module.css';
import * as staticImages from '../../staticImages';
import Lottie from 'react-lottie';
import animationData from './like.json';
import ReactTooltip from 'react-tooltip';

import { Box} from 'grommet';

class CardView extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            animate: false,
        }

        this.onClick = this.onClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.currentClubInfo !== this.props.currentClubInfo) {
            this.setState({animate: this.props.animate});
            if(this.props.animate === true) {
                setTimeout(function(){
                    this.setState({animate: false})
                }.bind(this), 1200)
            }
        }
    }

    onClick() {
        this.props.likeCallBack();

    }

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: { 
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        let bottom = 30;
        if (window.innerHeight < 812 == undefined) {
            bottom = window.innerHeight - 812 + 30;
        }
        if (window.innerHeight >= 896) {
            bottom = (window.innerHeight - 896) / 2 + 30;
        }
        
        if(window.innerWidth < 767 && window.innerHeight <= 812) {
            return (
                <Box id={styles.outer2} style={{top: "430px", marginBottom: "20px"}} data-tip data-for="replayTip1">
                    <Box id={styles.dislike2} onClick={() => this.props.reverseCallBack()}>
                        <div className={styles.replayBase}/>
                        <img src={staticImages.REPLAY} alt = "replay" className={styles.replayimg}/>
                    </Box>
                    <Box id={styles.like2}  onClick={() => this.props.likeCallBack()} data-tip data-for="replayTip2">
                        <div className={styles.replayBase} style={{background: "#ffffff"}}/>
                        <div id={styles.lottie}>
                            <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                        </div>
                        {(this.state.animate)?
                            <div id={styles.lottie}>
                                <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                            </div>
                            :
                            <div></div>
                        }
                    </Box>
                    <Box id={styles.replay2} onClick={() => this.props.dislikeCallBack()} data-tip data-for="replayTip3">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.DISLIKE} alt = "dislike" className={styles.dislikeimg}/>
                    </Box>
                    <ReactTooltip id="replayTip1" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Replay</ReactTooltip>
                    <ReactTooltip id="replayTip2" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Like & Subscribe</ReactTooltip>
                    <ReactTooltip id="replayTip3" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Not Interested</ReactTooltip>
                </Box>
            )
        } else if (window.innerWidth < 767) {
            return (
                <Box id={styles.outer2} style = {{bottom: `${bottom}px`}}>
                    <Box id={styles.dislike3} onClick={() => this.props.reverseCallBack()} data-tip data-for="replayTip1">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.REPLAY} alt = "replay" className={styles.replayimg}/>
                    </Box>
                    <Box id={styles.like3} style={{background: "#ffffff"}} onClick={() => this.props.likeCallBack()} data-tip data-for="replayTip2">
                        <div className={styles.replayBase}/>
                        <div id={styles.lottie}>
                            <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                        </div>
                        {(this.state.animate)?
                            <div id={styles.lottie}>
                                <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                            </div>
                            :
                            <div></div>
                        }
                    </Box>
                    <Box id={styles.replay2} onClick={() => this.props.dislikeCallBack()} data-tip data-for="replayTip3">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.DISLIKE} alt = "dislike" className={styles.dislikeimg}/>
                    </Box>
                    <ReactTooltip id="replayTip1" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Replay</ReactTooltip>
                    <ReactTooltip id="replayTip2" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Like & Subscribe</ReactTooltip>
                    <ReactTooltip id="replayTip3" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Not Interested</ReactTooltip>
                </Box>
            )
        } 
        else if (window.innerHeight <= 810) {
            return (
                <Box id={styles.outer} style = {{top: "490px"}}>
                    <Box id={styles.dislike} onClick={() => this.props.reverseCallBack()} data-tip data-for="replayTip1">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.REPLAY} alt = "replay" className={styles.replayimg}/>
                    </Box>
                    <Box id={styles.like} onClick={this.onClick} data-tip data-for="replayTip2">
                        <div className={styles.replayBase} style={{background: "#ffffff"}}/>
                        <div id={styles.lottie}>
                            <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                        </div>
                        {(this.state.animate)?
                            <div id={styles.lottie}>
                                <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                            </div>
                            :
                            <div></div>
                        }
                    </Box>
                    <Box id={styles.replay} onClick={() => this.props.dislikeCallBack()} data-tip data-for="replayTip3">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.DISLIKE} alt = "dislike" className={styles.dislikeimg}/>
                    </Box>
                    <ReactTooltip id="replayTip1" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Replay</ReactTooltip>
                    <ReactTooltip id="replayTip2" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Like & Subscribe</ReactTooltip>
                    <ReactTooltip id="replayTip3" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Not Interested</ReactTooltip>
                </Box>
            );
        }
        else {
            return (
                <Box id={styles.outer} style = {{bottom: `${bottom}px`}}>
                    <Box id={styles.dislike} onClick={() => this.props.reverseCallBack()} data-tip data-for="replayTip1">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.REPLAY} alt = "replay" className={styles.replayimg}/>
                    </Box>
                    <ReactTooltip id="replayTip1" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Replay</ReactTooltip>
                    <Box id={styles.like} onClick={this.onClick} data-tip data-for="replayTip2">
                        <div className={styles.replayBase} style={{background: "#ffffff"}}/>
                        <div id={styles.lottie}>
                            <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                        </div>
                        {(this.state.animate)?
                            <div id={styles.lottie}>
                                <Lottie options={defaultOptions} height={"90px"} width={"90px"} />
                            </div>
                            :
                            <div></div>
                        }
                    </Box>
                    <ReactTooltip id="replayTip2" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Like & Subscribe</ReactTooltip>
                    <Box id={styles.replay} onClick={() => this.props.dislikeCallBack()} data-tip data-for="replayTip3">
                        <div className={styles.replayBase}/>
                        <img src={staticImages.DISLIKE} alt = "dislike" className={styles.dislikeimg}/>
                    </Box>
                    <ReactTooltip id="replayTip3" className={styles.replayTip} place="top" effect="solid" delayShow={1000}>Not Interested</ReactTooltip>
                </Box>
            );
        }
    }
}
export default CardView;