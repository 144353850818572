import React from 'react';
import styles from './Login.module.css';
import { Redirect } from 'react-router-dom';
import { Image, Box, Grid } from 'grommet';
import ContentDesktop from './components/ContentDesktop';
import * as staticImages from '../staticImages';
import ErrorPopup from './components/ErrorPopup';
import LoginButton from './components/LoginButton';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopBackground: null,
            secondaryLogo: null,
            color: null,
            login: true,
            authenticator: "google",
            incognitoPopup: false,
            incognitoPopupCancelled: false
        }
    }

    componentDidMount() {
        if (window.location.hostname.split(".")[1] === "uniflowcampus") {
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getLoginData/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        domain: window.location.hostname
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                this.setState({
                    color: data.primaryColor,
                    secondaryLogo: data.logos[1],
                    desktopBackground: data.logos[3],
                    authenticator: data.authenticator,
                    login: window.location.hostname !== "demo.uniflowcampus.com"
                })
            })
            .catch((error) => console.log(error));
        }
        else {
            this.setState({
                color: "#03264E",
                secondaryLogo: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2Fumich.edu_secondary.png?alt=media&token=d9814adc-e612-4c26-a8fb-973bcab3d1b7",
                desktopBackground: staticImages.LOGIN_IMAGES.umich,
                authenticator: "google"
            })
        }
    }

    render() {
        if (this.props.incognito && !this.state.incognitoPopup && !this.state.incognitoPopupCancelled) {
            this.setState({incognitoPopup: true});
        }
        if (this.props.token != null) {
            return (
                <Redirect to="/virtualfair" />
            );
        }
        else if (window.innerWidth <= 833) {
            return (
                <Box id={styles.outer_box}>
                    <div id={styles.img_header}> 
                        <img id={styles.img} src={this.state.desktopBackground} alt="Blurred University logo"/>
                        <img id={styles.header_logo} src={this.state.secondaryLogo} alt="University logo" />

                    </div>
                    <div id={styles.powered_by}>Powered By</div>
                    <div id={styles.uniflow}>Uniflow.</div>
                    <Box id={styles.bottom_div}>
                        <Grid
                            id={styles.card_div} style={{ height:'auto', position: "relative"}}
                            rows={['30px','100px', 'auto']}
                            columns={["100%"]}                    
                            gap="none"
                            areas={[
                                { name: 'top', start: [0, 0], end: [0, 0] },
                                { name: 'pad', start: [0, 1], end: [0, 1] },
                                { name: 'bottom', start: [0, 2], end: [0, 2] },
                            ]}
                        >
                            <Box gridArea='top' id={styles.pair}>
                                <div id={styles.header}>Events</div>
                                <img id={styles.events} alt = "login" src={staticImages.LOGIN_EVENTS}/>
                            </Box>
                            <Box gridArea='bottom'>
                                <div id={styles.description}>Participate in events and meet the clubs (virtually) through video conferencing</div>
                            </Box>
                        </Grid>
                        <Grid
                            id={styles.card_div} style={{ height:'auto', position: "relative"}}
                            rows={['30px','100px', 'auto']}
                            columns={["100%"]}                    
                            gap="none"
                            areas={[
                                { name: 'top', start: [0, 0], end: [0, 0] },
                                { name: 'pad', start: [0, 1], end: [0, 1] },
                                { name: 'bottom', start: [0, 2], end: [0, 2] },
                            ]}
                        >
                            <Box gridArea='top' id={styles.pair}>
                                <div id={styles.header}>Discover</div>
                                <img id={styles.discover} alt = "login" src={staticImages.LOGIN_DISCOVER}/>
                            </Box>
                            <Box gridArea='bottom'>
                                <div id={styles.description}>Swipe through dozens of clubs and learn more about the ones you are interested in through club pages</div>
                            </Box>
                        </Grid>
                        <Grid
                            id={styles.card_div} style={{ height:'auto', position: "relative"}}
                            rows={['30px','100px', 'auto']}
                            columns={["100%"]}                    
                            gap="none"
                            areas={[
                                { name: 'top', start: [0, 0], end: [0, 0] },
                                { name: 'pad', start: [0, 1], end: [0, 1] },
                                { name: 'bottom', start: [0, 2], end: [0, 2] },
                            ]}
                        >
                            <Box gridArea='top' id={styles.pair}>
                                <div id={styles.header}>Join</div>
                                <img id={styles.join} alt = "login" src={staticImages.LOGIN_JOIN}/>
                            </Box>
                            <Box gridArea='bottom'>
                                <div id={styles.description}>Sign up for email lists, attend Info Sessions, check out club calandars and see everything you need to join clubs</div>
                            </Box>
                        </Grid>
                        <LoginButton loginState={this.state.login} login={this.props.login} authenticator={this.state.authenticator} color={this.state.color} />
                        {/* <div id={styles.google_button} style={{background: this.state.color}} onClick={() => this.props.login(this.state.login, this.state.authenticator)}>
                            {
                                this.state.login ? 
                                <div id={styles.sign_in_text}>Sign in with Google</div>
                                :
                                <div id={styles.sign_in_text}>Continue to Site</div>
                            }
                            <img src={staticImages.GOOGLE} alt = "google" id={styles.googleG} />
                        </div>  */}
                    </Box>
                </Box>
            );
        }
        else {
            return (
                <div>
                    <Box className={styles.leftDiv}>
                        <Box className={styles.darken}>
                            <Image className={styles.loginImg} fit="cover" src={this.state.desktopBackground} alt="Blurred University photo in background" />
                        </Box>
                        <Image className={styles.secondaryLogo} alignSelf="center" height="auto" width="60%" src={this.state.secondaryLogo} />
                        <div className={styles.bottomText1}>Powered By <span className={styles.bottomText2}>Uniflow.</span></div>
                    </Box>
                    <Box className={styles.rightDiv}>
                        <div className={styles.virtualFair}>Virtual Fair</div>
                        <Box className={styles.middleArea} gap="medium">
                            <ContentDesktop type="events" />
                            <ContentDesktop type="discover" />
                            <ContentDesktop type="join" />
                        </Box>
                        <div className={styles.signinDiv}>
                            <LoginButton loginState={this.state.login} login={this.props.login} authenticator={this.state.authenticator} color={this.state.color} />
                            {/* <div className={styles.googleButton} onClick={() => this.props.login(this.state.login, this.state.authenticator)} style={{background: `${this.state.color}`}}>
                                {
                                    this.state.login ? 
                                    <div id={styles.sign_in_text}>Sign in with Google</div>
                                    :
                                    <div id={styles.sign_in_text}>Continue to Site</div>
                                }
                                <img src={staticImages.GOOGLE} alt = "google" className={styles.GImgDesktop} />
                            </div> */}
                        </div>
                    </Box>
                    {
                        (this.state.incognitoPopup && !this.state.incognitoPopupCancelled) ? 
                        <ErrorPopup 
                            title="Error retrieving account" 
                            message="We can't retrieve your account. This can be fixed by checking your internet connection or turning off private browsing. Please contact your school administrator if you believe this is an error." 
                            closePopup={() => this.setState({incognitoPopupCancelled: true, incognitoPopup: false})} 
                        />
                        :
                        ""
                    }
                </div>
            )
        }
    }
}
export default Login;