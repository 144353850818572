import React from 'react';
import styles from './Card.module.css';
import {  Avatar, Box } from 'grommet';
import * as firebase from 'firebase/app';
import { db } from '../../index.js';
import { Textfit } from 'react-textfit';

import MeetUs from './MeetUs';

class Card extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            orgUUID: null,
            img: null,
            left: "20px",
            top: this.props.top,
            offset: [],
            isDown: false,
            live: false,
            exploreTop: "25%"
        };
        this.mouseDown = this.mouseDown.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
        this.mouseMove = this.mouseMove.bind(this);
        this.clickDown = this.clickDown.bind(this);
        this.clickMove = this.clickMove.bind(this);
        this.clickUp = this.clickUp.bind(this);
    }

    componentDidMount() {
        // if(window.innerWidth < 400) {
        //     this.state.left = '10%';
        //     this.state.top = "21%";
        //     this.state.exploreTop = "20%";
        // } else if(window.innerWidth < 767) {
        //     this.state.left = "calc(50% - 190px)";
        //     this.state.top = "15%";
        // }
        // if(window.innerHeight < 600){
        //     this.state.top = '110px';
        // }
        if(this.props.currentClubInfo) {
            this.getClubImage();
        }

        
        
        
        var isLive = false;
        if(this.props.currentClubInfo !== null) {
            for(var key in this.props.currentClubInfo.live) {                
                if(this.props.currentClubInfo.live[key] === true &&  this.props.currentClubInfo.zoom[key] != "" &&  this.props.currentClubInfo.zoom[key] != undefined) {
                    isLive = true;
                    break;
                }
            }
            this.setState({live: isLive});
        }
    }

    componentDidUpdate(prevProps) {
            if(prevProps.currentClubInfo !== this.props.currentClubInfo) {
                var isLive = false;
                for(var key in this.props.currentClubInfo.live) {                
                    if(this.props.currentClubInfo.live[key] === true &&  this.props.currentClubInfo.zoom[key] != "" &&  this.props.currentClubInfo.zoom[key] != undefined) {
                        isLive = true;
                        break;
                    }
                }
                this.setState({live: isLive});
                
            
            } 
            if(prevProps.top !== this.props.top) {
                this.setState({top: this.props.top});
            } 
            
    } 

    getClubImage() {
        if (this.props.currentClubInfo && this.props.currentClubInfo.uuid !== this.state.orgUUID) {
            if(this.props.currentClubInfo.logo === undefined){
                this.setState({img: this.props.currentClubInfo.album[0], orgUUID: this.props.currentClubInfo.uuid});
            }

            else {
                this.setState({img: this.props.currentClubInfo.logo, orgUUID: this.props.currentClubInfo.uuid});
            }
            
        }
    }

    mouseDown(event) {
       
        if(window.innerWidth < 400) {
            event.preventDefault();
        this.setState({
            isDown: true,
            offset: [
                // (window.innerWidth * .2)/2 - 11 - event.touches[0].clientX,
                // window.innerHeight * .15 - 15 - event.touches[0].clientY
                parseInt(this.state.left.substring(0, this.state.left.length -2)) + 40,
                event.touches[0].clientX,
                parseInt(this.state.top.substring(0, this.state.top.length -2)),
                event.touches[0].clientY
            ]
        })
        } else if (window.innerWidth < 767) {
            event.preventDefault();
        this.setState({
            isDown: true,
            offset: [
                // (window.innerWidth - 380)/2 - 11 - event.touches[0].clientX,
                // window.innerHeight * .15 - 15 - event.touches[0].clientY
                window.innerWidth * .5 - 190,
                 event.touches[0].clientX,
                parseInt(this.state.top.substring(0, this.state.top.length -2)),
                event.touches[0].clientY
            ]
        })
        }else if(this.props.spotlight === 62) {
            event.preventDefault();
            this.setState({
                isDown: true,
                offset: [
                    // (window.innerWidth - 380)/2 - 11 - event.clientX,
                    // window.innerHeight * .15 - 15 - event.clientY
                    window.innerWidth * .5 - 205,
                    event.touches[0].clientX,
                    (window.innerHeight - 573)/2 -94,
                    // parseInt(this.state.top.substring(0, this.state.top.length -2)),
                    event.touches[0].clientY
                ]
            })
        } else {
            event.preventDefault();
        this.setState({
            isDown: true,
            offset: [
                // (window.innerWidth - 380)/2 - 11 - event.touches[0].clientX,
                // window.innerHeight * .15 - 15 - event.touches[0].clientY
                window.innerWidth * .5 - 345,
                event.touches[0].clientX,
                (window.innerHeight - 573)/2 -94,
                // parseInt(this.state.top.substring(0, this.state.top.length -2)),
                event.touches[0].clientY
            ]
        })
        }

        
    }

    clickDown(event) {
        event.preventDefault();
        this.setState({
            isDown: true,
        })
        if(window.innerWidth < 400) {
            this.setState({
                offset: [
                    // (window.innerWidth * .2)/2 - 11 - event.clientX,
                    // window.innerHeight * .15 - 15 - event.clientY
                    parseInt(this.state.left.substring(0, this.state.left.length -2)) + 40,
                    event.clientX,
                    parseInt(this.state.top.substring(0, this.state.top.length -2)),
                     event.clientY
                    
                ]
            })
        } else if(window.innerWidth < 767) {
            this.setState({
                offset: [
                    // (window.innerWidth - 380)/2 - 11 - event.clientX,
                    // window.innerHeight * .15 - 15 - event.clientY
                    window.innerWidth * .5 - 190,
                    event.clientX,
                   parseInt(this.state.top.substring(0, this.state.top.length -2)),
                    event.clientY
                ]
            })
        }else if(this.props.spotlight === 62) {
            this.setState({
                isDown: true,
                offset: [
                    // (window.innerWidth - 380)/2 - 11 - event.clientX,
                    // window.innerHeight * .15 - 15 - event.clientY
                window.innerWidth * .5 - 205,
                     event.clientX,
                     (window.innerHeight - 573)/2 - 94,
                    // parseInt(this.state.top.substring(0, this.state.top.length -2)),
                     event.clientY
                ]
            })
        }else {
            this.setState({
                isDown: true,
                offset: [
                    // (window.innerWidth - 380)/2 - 11 - event.clientX,
                    // window.innerHeight * .15 - 15 - event.clientY
                window.innerWidth * .5 - 345,
                     event.clientX,
                     (window.innerHeight - 573)/2 - 94,
                    // parseInt(this.state.top.substring(0, this.state.top.length -2)),
                     event.clientY
                ]
            })
        }
    }

    mouseUp(event) {
        if(window.innerWidth < 400) {
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth * .2) {

                this.props.likeButtonPressed();
            }
            if(this.state.left < '0px') {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                top: this.props.top,
                left: "20px"
            })
        } else if(window.innerWidth < 767) {
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth - 380) {

                this.props.likeButtonPressed();
            }
            if(this.state.left < '0px') {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                top: this.props.top,
                left: "20px"
            })
        } else {
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth * .5 - 190) {
                this.props.likeButtonPressed();
            }
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) < window.innerWidth * .5 - 380) {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                left: "calc(50% - 187.5px)",
                top: this.props.top,
            })
        }
        

    }

    clickUp(event) {
        event.preventDefault();

        if(window.innerWidth < 400) {
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth * .2) {

                this.props.likeButtonPressed();
            }
            if(this.state.left < '0px') {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                top: this.props.top,
                left: "20px"
            })
        } else if(window.innerWidth < 767){
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth - 380) {
                this.props.likeButtonPressed();
            }
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) < 0) {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                top: this.props.top,
                left: "20px"
            })
        } else {
            this.setState({
                isDown: false,
            })
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) > window.innerWidth * .5 - 190) {
                this.props.likeButtonPressed();
            }
            if(parseInt(this.state.left.substring(0, this.state.left.length -2)) < window.innerWidth * .5 - 380) {
                this.props.dislikeButtonPressed();
            }
            this.setState({
                left: "calc(50% - 187.5px)",
                top: this.props.top,
            })
        }

    }

    mouseMove(event) {
        if(this.state.isDown) {
            this.setState({
                left: event.touches[0].clientX + this.state.offset[0] - this.state.offset[1] + 'px',
                top: event.touches[0].clientY + this.state.offset[2] - this.state.offset[3] + 'px'
            })
        }

    
    }

    clickMove(event) {
      
        event.preventDefault();
        if(this.state.isDown) {
            this.setState({
                left: event.clientX + this.state.offset[0] - this.state.offset[1] + 'px',
                top: event.clientY + this.state.offset[2] - this.state.offset[3] + 'px'
            })


        }
    }

    logExpandClub = () => {
        const userID = firebase.auth().currentUser.uid;
        const date = new Date();
        try {
            const club = this.props.currentClubInfo.uuid ?? 'unknown';
            window.ga('send', 'event', 'Club Page Interaction', 'Club Page Visit', 'Swipe View');
            db.collection('logged_events').add({
                'category': 'Club Page Interaction',
                'action': 'Club Page Visit',
                'view_mode': 'Swipe',
                'time': date,
                'user': userID,
                'club': club,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Club Page Visit',
                'view_mode': 'Swipe',
                'time': date,
                'url': window.location.href,
            });
        }
    }

    render() {
        this.getClubImage();
        if (this.props.currentClubInfo == null) {
            return (
                <div id={styles.card}></div>
            );
        }
        if(window.innerHeight < 810) {
            if(this.state.top === '15%'){
                this.state.top = '115px';
            }
            
        }
        if(window.innerWidth < 767/* && window.innerWidth >= 400*/) {
            if(this.state.left === '10%') {
                this.state.left = 'calc(50% - 190px)';
            }
            let height = "calc(100% - 125px)";
            let avatar = (window.innerHeight - 812) / 2 + 100;
            let button = "20px";
            if (window.innerHeight >= 896) {
                height = "473px";
                avatar = 150;
                button = "40px";
                if (this.state.top === "0px") {
                    this.state.top = `${(window.innerHeight - 820) / 2}px`
                }
                if (this.state.top === "10px") {
                    this.state.top = `${(window.innerHeight - 820) / 2 + 10}px`
                }
            }
            else if (window.innerHeight < 812) {
                avatar = 100;
                height = "389px";
            }
            return (
            <Box id={styles.card2} style = {{left: this.state.left, top: this.state.top, height: height}} onTouchMove = {this.mouseMove} onTouchStart = {this.mouseDown} onTouchEnd = {this.mouseUp} onMouseDown = {this.clickDown} onMouseMove = {this.clickMove} onMouseUp = {this.clickUp}>
                <div id={styles.backcard}/>

                <Avatar src={this.state.img} style={{position: "absolute", top: "23px", width: `${avatar}px`, height: `${avatar}px`, left: `calc(50% - ${avatar / 2}px)`}} alt = "logo"/>
                <div id={styles.titleDiv} style={{top: `${avatar + 46}px`}}>
                    <Textfit max={20} mode="multi" style={{height: "100%", width: "100%"}}>
                        {this.props.currentClubInfo.name}
                    </Textfit>
                </div>
                <div id={styles.descriptionDiv} style={{top: `${avatar + 105}px`}}>
                    <Textfit max={15} mode="multi" style={{height: "100%", width: "100%"}}>
                        {this.props.currentClubInfo.short_description}
                    </Textfit>
                </div>
                <div id={styles.btn} style={{bottom: button}} onClick={() => {this.logExpandClub(); this.props.expandClub(this.props.currentClubInfo, false);}}>
                    <div id={styles.exploreBase}  style = {{background: this.props.color}}/>
                    <div id={styles.explore} style = {{top: this.state.exploreTop}}>Explore</div>
                </div>
                {
                    (this.state.live)?
                    <div id={styles.live_div}>
                        <MeetUs />
                    </div>
                    :
                    <div></div>
                }
            </Box>
            );
        } /*else if(window.innerWidth < 400) {
            if(this.state.left === 'calc(50% - 190px)') {
                this.state.left = '10%';
            }
            
            return (
            <Box id={styles.card3} style = {{left: this.state.left, top: this.state.top}} onTouchMove = {this.mouseMove} onTouchStart = {this.mouseDown} onTouchEnd = {this.mouseUp} onMouseDown = {this.clickDown} onMouseMove = {this.clickMove} onMouseUp = {this.clickUp}>

                <div id={styles.backcard}/>
                { <div id={styles.base}/>
                <div id={styles.base1}/>
                <div id={styles.base2}/> }
                <Avatar src={this.state.img} id={styles.logo2} alt = "logo"/>
                <div id={styles.title2}>{this.props.currentClubInfo.name}</div>
                <div id={styles.description3}>{this.props.currentClubInfo.short_description}</div>

                <div id={styles.btn} onClick={() => {this.logExpandClub(); this.props.expandClub(this.props.currentClubInfo, false);}}>
                    <div id={styles.exploreBase}  style = {{background: this.props.color}}/>
                    <div id={styles.explore} style = {{top: this.state.exploreTop}}>Explore</div>
                </div>
                {
                    (this.state.live)?
                    <div id={styles.live_div}>
                        <MeetUs />
                    </div>
                    :
                    <div></div>
                }
            </Box>
            );
}*/
        else {
            var avatar;
            var max;
            if(this.props.height === "510px") {
                avatar = 175;
                max = 26;
                
            }
            else {
                avatar = 147.833;
                max = 22;
                
            }
            if(window.innerWidth > 767 && !this.state.isDown) {
                this.state.left = 'calc(50% - 187.5px)';
            }
        return (
            <Box>
                <Box id={styles.card} style = {{ left: this.state.left, top: this.state.top, width: "375px", height: this.props.height}} onTouchMove = {this.mouseMove} onTouchStart = {this.mouseDown} onTouchEnd = {this.mouseUp} onMouseDown = {this.clickDown} onMouseMove = {this.clickMove} onMouseUp = {this.clickUp}>
                    <Avatar src={this.state.img} style={{position: "absolute", top: "23px", width: `${avatar}px`, height: `${avatar}px`, left: `calc(50% - ${avatar / 2}px)`}}  alt = "logo"/>
                    <div id={styles.title}>
                        <Textfit max={max} mode="multi" style={{height: "100%", width: "100%"}}>
                            {this.props.currentClubInfo.name}
                        </Textfit>
                    </div>
                    <div id={styles.description} style={{top: "calc(41.3% + 32px + 56px)", height: "calc(30% - 31px)", position: "absolute"}}>
                        <Textfit max={15} mode="multi" style={{height: "100%", width: "100%"}}>
                            {this.props.currentClubInfo.short_description}
                        </Textfit>
                    </div>
                    <div id={styles.btn} style={{bottom: "23px",  position: "absolute", height: "40px"}} onClick={() => {this.logExpandClub(); this.props.expandClub(this.props.currentClubInfo, false);}}>
                        <div id={styles.exploreBase}  style = {{background: this.props.color}}/>
                        <div id={styles.explore} style = {{top: this.state.exploreTop}}>Explore</div>
                    </div>
                    {
                        (this.state.live)?
                        <div id={styles.live_div}>
                            <MeetUs />
                        </div>
                        :
                        <div></div>
                    }
                </Box>
            </Box>
        );
        }
    }
}
export default Card;