import React from 'react';
import styles from './GridCard.module.css';
import { Grid, Box, Image} from 'grommet';
import * as staticImages from '../../staticImages';
import * as firebase from 'firebase/app';
import { Textfit } from 'react-textfit';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
// import Popup from "reactjs-popup";

// import ClubPage from '../../ClubPage/ClubPage';
import Heart from './Heart';
import LiveGridCard from './LiveGridCard';
import { db } from '../../index.js';

// import { relativeTimeThreshold } from 'moment';

// passes info: dictionary with .UUID, .name, and .description
class GridCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            heart: this.props.heart,
            img: "",
            live: false,
            zoom: "",
        }
        this.getClubImg = this.getClubImg.bind(this);
        this.changeHeart = this.changeHeart.bind(this);
    }

    componentDidMount() {
        this.getClubImg();
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.info !== this.props.info) {
            this.getClubImg();
        }
    }

    getClubImg() {
        var isLive = false;
        for(var key in this.props.info.live) {                
            if(this.props.info.live[key] === true && this.props.info.zoom[key] != undefined &&  this.props.info.zoom[key] != "") {
                isLive = true;
                
                this.setState({
                    zoom: this.props.info.zoom[key],
                });
                break;
            }
        }
        this.setState({
            club: this.props.info,
            heart: this.props.heart,
            img: this.props.info.album[0],
            live: isLive,
        })
    }

    changeHeart() {
        this.setState(prevState=>({
            heart: !prevState.heart
        }))
    }

    logExpandClub = () => {
        const userID = firebase.auth().currentUser.uid;
        const date = new Date();
        try {
            const club = this.props.info.uuid ?? 'unknown';
            window.ga('send', 'event', 'Club Page Interaction', 'Club Page Visit', 'Grid View');
            db.collection('logged_events').add({
                'category': 'Club Page Interaction',
                'action': 'Club Page Visit',
                'view_mode': 'Grid',
                'time': date,
                'user': userID,
                'club': club,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Club Page Visit',
                'view_mode': 'Grid',
                'time': date,
                'url': window.location.href,
            });
        }
    }

    render() {
        if (this.props.view === "mobile") {
            let width = window.innerWidth - 100;
            let height = 1.227 * width;
            return (
                <Grid
                    id={styles.mobile_card_div}
                    height={{min: `${height}px`, max: `${height}px`}}
                    width={{min: `${width}px`, max: `${width}px`}}
                    rows={[`${0.4 * height}px`, `${0.1375 * height}px`, `${0.25 * height}px`, `${0.2125 * height}px`]}
                    columns={['100%']}                    
                    gap="none"
                    alignSelf="center"
                    margin="20px"
                    areas={[
                        { name: 'image', start: [0, 0], end: [0, 0] },
                        { name: 'name', start: [0, 1], end: [0, 1] },
                        { name: 'description', start: [0, 2], end: [0, 2] },
                        { name: 'buttons', start: [0, 3], end: [0, 3] },
                    ]}
                >
                    <Box gridArea='image'>
                        <LazyLoadComponent>
                            <Image fit="cover" src={this.state.img} alt = "logo" id={styles.club_logo} />
                        </LazyLoadComponent>
                    </Box>
                    <Box gridArea='name' id={styles.club_name}>{this.props.info.name}</Box>
                    <Box gridArea='description' id={styles.club_description}>{this.props.info.short_description}</Box>
                    
                    <Box gridArea='buttons' onClick={() => {this.logExpandClub(); this.props.expandClub(this.props.info, this.state.heart);}} id={styles.explore_div} background={this.props.color}>
                        <div id={styles.explore}>Explore</div>
                    </Box>
                    <div id={styles.heart}>
                        <Heart 
                            token = {this.props.token}
                            clubUUID = {this.props.info.uuid}
                            name = {this.props.info.name}
                            unfavorite = {this.props.unfavorite}
                            heart = {this.props.heart}
                            empty = {staticImages.EMPTY_HEART_GREY}
                            filled = {staticImages.FILLED_HEART}
                            changeHeart = {this.changeHeart}
                            addToLocalMailingList = {this.props.addToLocalMailingList}
                        />
                    </div>
                    {
                        (this.state.live)?
                        <div id={styles.live_div}>
                            <LiveGridCard zoom = {this.state.zoom} addToLocalMailingList = {this.props.addToLocalMailingList} joined={this.props.heart} clubUUID={this.props.info.uuid} />
                        </div>
                    :
                        <div></div>
                    }
                </Grid> 
            );
        }
        return(
            <Grid
                id={styles.card_div}
                rows={['160px', '75px', '120px', '85px']}
                columns={['100%']}                    
                gap="none"
                alignSelf="center"
                margin="20px"
                areas={[
                    { name: 'image', start: [0, 0], end: [0, 0] },
                    { name: 'name', start: [0, 1], end: [0, 1] },
                    { name: 'description', start: [0, 2], end: [0, 2] },
                    { name: 'buttons', start: [0, 3], end: [0, 3] },
                ]}
            >
                <Box gridArea='image'>
                    <LazyLoadImage src={this.state.img} alt = "logo" id={styles.club_logo} />
                    {/* <LazyLoadComponent>
                        <img src={this.state.img} alt = "logo" id={styles.club_logo} />
                    </LazyLoadComponent> */}
                </Box>
                <Box gridArea='name' id={styles.club_name_container}>
                    <Textfit max={22} mode="multi" style={{height: "100%"}}>
                        {this.props.info.name}
                    </Textfit>
                </Box>
                <Box gridArea='description' id={styles.club_description}>
                    <Textfit max={15} mode="multi" style={{height: "100%", width: "100%"}}>
                        {this.props.info.short_description}
                    </Textfit>
                </Box>
                <Box 
                    gridArea='buttons' 
                    onClick={() => {
                        this.logExpandClub(); 
                        this.props.expandClub(this.props.info, this.state.heart);
                    }} 
                    id={styles.explore_div} 
                    background={this.props.color}
                >
                    <div id={styles.explore}>
                        Explore
                    </div>
                </Box>

                <div id={styles.heart}>
                    <Heart 
                        token = {this.props.token}
                        clubUUID = {this.props.info.uuid}
                        name = {this.props.info.name}
                        unfavorite = {this.props.unfavorite}
                        heart = {this.props.heart}
                        empty = {staticImages.EMPTY_HEART_GREY}
                        filled = {staticImages.FILLED_HEART}
                        changeHeart = {this.changeHeart}
                        addToLocalMailingList = {this.props.addToLocalMailingList}
                    />
                </div>
                {
                    (this.state.live)?
                    <div>
                        <div id={styles.live_div}>
                            <LiveGridCard zoom = {this.state.zoom} addToLocalMailingList = {this.props.addToLocalMailingList} joined={this.props.heart} clubUUID = {this.props.info.uuid} />
                        </div>
                        <img  src={this.state.img} alt = "logo" id={styles.live_logo}/>
                    </div>
                :
                    <div></div>
                }
            </Grid> 
        );
    }
}

export default GridCard;