import React from 'react';
import styles from './ClubPreview.module.css';

import {Image} from 'grommet';

import * as staticImages from '../../staticImages';

function Logo({image, index, name}){
    if(image) {
        return (
            <Image key={index} src={image}  style={{left: `${16 + index*18}px`}} id={styles.club} />
    
        )
    } else {
        let initial = name[0];
        return (
            <div key={index} style={{left: `${16 + index*18}px`}} id={styles.club} >
                <div id = {styles.initial}>
                    {initial}

                </div>
            </div>
    
        )
    }
    
}


class ClubPreview extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            number: this.props.clubs.length - 3,
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.images.map(
                    (image,index) => (
                        <Logo 
                            image = {image}
                            index = {index}
                            name = {this.props.event.clubNames[index]}
                        />
                    ))
                }
                {
                    (this.state.number > 0)? 
                    <div id={styles.club_count_div}>
                        <div id={styles.club_count}>+{this.state.number}</div>
                    </div>
                    :
                    <div></div>
                }
            </div>
        );
    }
}

export default ClubPreview;