import React from 'react';
import styles from './EventBanner.module.css';
import {Box, Image} from 'grommet';
import FormatDate from './FormatDate';
import * as staticImages from '../../staticImages';

function EventBanner({event, changeEvent, eventIndex, query, setSearchQuery, timezone}) {
    let top = '30px';
    if(window.innerWidth < 767) {
        top = '55px';
    }
    let text = "Searching For";
    if(event) {
        text = "Searching \"" + event.name + "\" For";
    }
    if(query) {
        return(
            <Box id = {styles.outerBox} style = {{top: top, height: '130px'}}>
                <img src = {staticImages.MULTIPLY} alt = "close" id = {styles.xIcon2} onClick = {() => setSearchQuery("")}/>
                <img src = {staticImages.SEARCH} alt = "search" id = {styles.searchIcon} />
                <div id = {styles.searching}>
                    {text}
                </div>
                <div id = {styles.query} >
                {query.charAt(0).toUpperCase() + query.slice(1)}

                </div>
            </Box>
        )
    } else {
    return (
        <Box id = {styles.outerBox} style = {{top: top}}>
            <Image style = {{borderRadius: '20px'}} fit="cover" src="https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FUniflow_graphic.png?alt=media&token=6b0240df-2d25-46d6-abd3-77b4cf85d033" id = {styles.image2}/>
            <div id = {styles.leaveButton} onClick = {() => changeEvent(eventIndex)}>
                <div id = {styles.leave}>
                    Leave
                </div>
                <img src = {staticImages.LEAVE} alt = "Leave" id = {styles.leaveIcon}></img>
            </div>
            <div id = {styles.eventTitle}>
                {event.name}
            </div>
            <div id = {styles.date}>
                <FormatDate 
                    start = {event.time[0]}
                    end = {event.time[1]}
                    color = "#ffffff"
                    size = '17px'
                    timezone = {timezone}
                />
            </div>
        </Box>
    )
    }

}
export default EventBanner;