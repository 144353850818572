import React from 'react';
import styles from './ProfileInfo.module.css';
import { Box, Image, Avatar } from 'grommet';
import Textfit from 'react-textfit';
import * as staticImages from '../../staticImages';

class ProfileInfo extends React.Component {
    render() {
        let name = (this.props.name == null)? "Guest" : this.props.name;
        return (
            <Box direction="row" style={this.props.style} gap="small"> 
                {
                    (this.props.profilePicture == null) ?
                    <Avatar background="#C9D9E8">
                        <img src = {staticImages.GUEST} style = {{width: '40px'}}/>
                    </Avatar>
                    :
                    <Avatar src={this.props.profilePicture} />
                }
                <Box>
                    <div className={styles.userNameDiv}>
                        <Textfit max={22} mode="single" className={styles.userName} style={{height: "100%"}}>{name}</Textfit>
                    </div>
                    <Box onClick={this.props.signOut} direction="row" gap="small" style={{cursor: "pointer"}}>
                        <div className={styles.signOut}>Sign Out</div>
                        <Image src={staticImages.LOGOUT} height="15px" width="auto" style={{position: "relative", background: "transparent", top: "5px"}} />
                    </Box>
                </Box>
            </Box>
        );
    }
}
export default ProfileInfo;