import React from 'react';
import styles from './CarouselDot.module.css';

class CarouselDot extends React.Component {
    render() {
        let color = (this.props.color === undefined) ? "white" : "#CCCCCC";
        return (
            <div className={styles.dot} style={{background: color}} />
        );
    }
}
export default CarouselDot;