import React from 'react';
import styles from './FilterBox.module.css';

import {Box} from 'grommet';



class FilterBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style: this.props.style,
        };
    
    }


    render(){

        if(this.props.index < this.props.length - 1){
            return(
                <Box className = {styles.outerBox} style = {{background: this.props.background, left: this.props.left, width: this.props.width}} >
                    <span>{this.props.tag}</span>
    
                    <img src ={this.props.xIcon} alt = "delete" className = {styles.x}  onClick={() => {this.props.removeTag(this.props.tag); this.props.loadClubs()}}/>

                </Box>
           )
        } else {

        
            return(
                 <Box className = {styles.outerBox}  style = {{background: '#1455A6', left: this.props.left, width: '95px', cursor: 'pointer'}} onClick = {()=> this.props.clearTags()}>
                     <div id = {styles.clearText}>
                        CLEAR

                     </div>
                 </Box>
            )
        }
    }
}

export default FilterBox;