import React from 'react';
import styles from './FilterPopup.module.css';
import * as staticImages from '../../staticImages';


import {Box, Layer} from 'grommet';

import FilterOption from './FilterOption';
import DropdownItem from './DropdownItem';


class FilterPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.tags,
            xIcon: staticImages.MULTIPLY,
            xIcon2: staticImages.EXIT,
            arrow: staticImages.ARROW,
            dropdownOpen: false
        };
        this.selectedTag = this.selectedTag.bind(this);
    
    }

    componentDidMount() {
       
    }

    selectedTag(tag) {
        if(this.props.tags_selected.indexOf(tag)> -1) {
            return true;
        } else {
            return false;
        }

    }

    render(){
        if(this.props.isOpen){
            let totalPrint = this.props.tags.slice();
            // if(this.props.query && totalPrint.indexOf(this.props.query) === -1){
            //     totalPrint.unshift(this.props.query);
            // }
            let options = [];
            if (this.state.dropdownOpen) {
                if (this.props.dropdownState === "category") {
                    options = ["A-Z", "Z-A"];
                }
                else if (this.props.dropdownState === "A-Z") {
                    options = ["Z-A", "category"];
                }
                else if (this.props.dropdownState === "Z-A") {
                    options = ["A-Z", "category"];
                }
            }

            if(window.innerWidth < 767){
                return (
                    <Layer style={{background: "none"}} onEsc={() => this.props.open()} onClickOutside={() => this.props.open()}>
                        <Box background="white" round="0px" width= {window.innerWidth} id = {styles.mobileBox}>
                        <div id = {styles.topBox}  style = {{background: this.props.color}}>
                            <div id = {styles.titleMobile} style = {{color: '#ffffff'}}>
                                Sort & Filter
                            </div>
                            <div id = {styles.closeButtonMobile} onClick = {this.props.open}>
                                <div id = {styles.doneMobile}>
                                    Done
                                </div>
                            </div>

                        </div>
                        
                        <div id = {styles.scroll2}>
                                 {
                             totalPrint.map(
                                 (tag,index) => (
                                 <FilterOption 
                                     key = {index}
                                     index = {index}
                                     tag = {tag}
                                     top =  {`${80*index}px`}
                                     selected = {this.selectedTag(tag)}
                                     addTag = {this.props.addTag}
                                     removeTag = {this.props.removeTag}
                                     background = {this.props.colors[index%4]}
                                     loadClubs = {this.props.loadClubs}
                                     xIcon = {this.state.xIcon2}
                                     arrow = {this.state.arrow}
                                     query = {this.props.query}
                                     removeQuery = {this.props.removeQuery}
                                     color = {this.props.color}
                                 />
                                 )
                             )
                             }
                             </div>
                             <div className = {styles.clear2} onClick = {this.props.clearTags}>
                                <div className = {styles.clearText}>
                                    Clear Filters
                                </div>
                            </div>
                            <div className={styles.dropdownOutline}>
                            <DropdownItem type={this.props.dropdownState} onClick={() => this.setState({dropdownOpen: !this.state.dropdownOpen})} state="title" dropdownOpen={this.state.dropdownOpen} />
                            {
                                (this.state.dropdownOpen) ? 
                                <div>
                                    <DropdownItem type={options[0]} onClick={() => {
                                        this.props.changeDropdown(options[0]);
                                        this.setState({dropdownOpen: !this.state.dropdownOpen});
                                    }} state="menu" />
                                    <DropdownItem type={options[1]} onClick={() => {
                                        this.props.changeDropdown(options[1]);
                                        this.setState({dropdownOpen: !this.state.dropdownOpen});
                                    }} state="menu" />
                                </div>
                                :
                                ""
                            }
                        </div>
                        </Box>
                    </Layer>
                )
            } else {
       return(
            <Layer style={{background: "none"}} onEsc={() => this.props.open()} onClickOutside={() => this.props.open()}>
                <Box background="white" round="25px" width= "457px" height="668px">
                <div id = {styles.closeButton} onClick = {this.props.open}>
                    <div id = {styles.done} >
                        Done
                    </div>
                </div>             
                <div className = {styles.title} style = {{color: this.props.color}}>
                    Filters
                </div>
                <div className = {styles.line} style = {{background: '#1455A6'}}></div>
                <div className = {styles.scroll}>

                        {
                    totalPrint.map(
                        (tag,index) => (
                        <FilterOption 
                            key = {index}
                            index = {index}
                            tag = {tag}
                            top =  {`${80*index}px`}
                            selected = {this.selectedTag(tag)}
                            addTag = {this.props.addTag}
                            removeTag = {this.props.removeTag}
                            background = {this.props.colors[index%4]}
                            loadClubs = {this.props.loadClubs}
                            xIcon = {this.state.xIcon2}
                            arrow = {this.state.arrow}
                            query = {this.props.query}
                            removeQuery = {this.props.removeQuery}
                            color = {this.props.color}

                        />
                        )
                    )
                    }
                    </div>
                    <div className = {styles.clear} onClick = {this.props.clearTags}>
                        <div className = {styles.clearText}>
                            Clear Filters
                        </div>
                    </div>
                </Box>
            </Layer>
  
       )}
        } else {
            return (
                <div></div>
            )
        }
    }
}

export default FilterPopup;