import React from 'react';
import styles from './ConfirmationThrow.module.css';
import * as staticImages from '../../staticImages';

class ConfirmationThrow extends React.Component {
    render() {
        setTimeout(this.props.close, 1000);
        if (this.props.state === "like") {
            return (
                <div className={styles.outerDiv}>
                    <img src={staticImages.CONFIRMATION_LIKE} className={styles.img} alt="like" />
                    <div className={styles.text}>Added to Favorites</div>
                </div>
            )
        }
        if (this.props.state === "pass") {
            return (
                <div className={styles.outerDiv}>
                    <img src={staticImages.CONFIRMATION_PASS} className={styles.img} alt="like" />
                    <div className={styles.text}>Pass</div>
                </div>
            )
        }
        if (this.props.state === "replay") {
            return (
                <div className={styles.outerDiv}>
                    <img src={staticImages.CONFIRMATION_REPLAY} className={styles.img} alt="like" />
                    <div className={styles.text}>Replaying</div>
                </div>
            )
        }
    }
}
export default ConfirmationThrow;