import React from 'react';
// import styles from './Discover.module.css';
import { Redirect } from 'react-router-dom';
import * as firebase from 'firebase/app';

import { Grommet, Box } from 'grommet';
import MainHeader from '../MainHeader/MainHeader';
import Spotlight from './components/Spotlight';
import CardView from './components/CardView';
import GridView from './components/GridView';
import Favorites from './components/Favorites';
//import ClubPage from '../ClubPage/ClubPage';
import ClubPage from '@bit/uniflowdevelopment.virtualfair.clubpage';
import CarouselDot from './components/CarouselDot';
import CarouselNext from './components/CarouselNext';
import CarouselPrev from './components/CarouselPrev';
import FilterPopup from './components/FilterPopup';
import OnboardingPopup from './components/OnboardingPopup';
import { db } from '../index';

class Discover extends React.Component {
    constructor(props) {
        super(props);
        var request = false;
        var min = 0
        var max = 10
        var rand =  min + (Math.random() * (max-min));
        if(rand < 3){
            request = true;
        }
        this.state = {
            view: "grid",
            spotlight: 311,
            selected: -1,
            searchQuery: null,
            queries: [],
            test: true,
            tags: [],
            tags_selected: [],
            favoritesWindowSize: "off", // can be "off", "small", or "large"
            clubPageWindowSize: "off",
            unfavoritedClub: null,
            clubInfo: null,
            clubUUIDs: null,
            filterOpen: false,
            reload: false,
            requestFeatures: request,
            colors: [
                `linear-gradient(90deg, #1488CC 0%, #2B32B2 100%)`,
                `linear-gradient(90deg, #BD7AE3 0%, #A811DA 100%)`,
                `linear-gradient(90deg, #606C88 0%, #3F4C6B 100%)`,
                `linear-gradient(90deg, #232526 0%, #595B5E 100%)`

            ],
            events: [],
            dropdownState: "A-Z",
            onboardingState: 0,
            todaysEvents: 
            [ /*{
                orgInfo:
            
                {
                   name: "VolleyBall Club",
                   zoom: 
                   {
                    "1234": "google"
                   },
                   tag: "",
                   uuid: "10",
                   email:"mrose@4roses.net",
                   keyReasons: [],
                   long_description: "",
                   mailingList: {},
                   medium_description: "",
                   memories: [],
                   numMembers: 0,
                   numPhotots: 1,
                   officers: [],
                   short_description: "",
                   socialEvents: "5-8",
                   socialLinks: {},
                   weeklyHours: 0,
                   logo: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2f9e234d56-de8e-11ea-bae4-3e128e7e5bbc?alt=media",
                   album: ["https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2f9e234d56-de8e-11ea-bae4-3e128e7e5bbc?alt=media"],
                   alive: true,
                },
                event: {
                    name: "involvment fair",
                    description: "",
                    time: ["1597878000", "1597885200"],
                    uuid: "1234",
                    clubs: ["10"],
                    clubNames: ["VolleyBall Club"],
                }
            } */
            ],
            theme: {
                global: {
                    focus: {
                        border: {
                            color: "None"
                        }
                    }
                },
                layer: {
                    overlay: {
                        background: "rgba(0, 0, 0, 0.8)"
                    }
                },
                carousel: {
                    icons: {
                        current: CarouselDot,
                        next: CarouselNext,
                        previous: CarouselPrev,
                        color: true,
                    },
                    disabled: {
                        icons: {
                            color: false,
                        }
                    }
                }
            }
        }
        
        this.changeView = this.changeView.bind(this);
        this.hideSpotlight = this.hideSpotlight.bind(this);
        this.changeEvent = this.changeEvent.bind(this);
        this.setSearchQuery = this.setSearchQuery.bind(this);
        this.changeFavoritesWindowSize = this.changeFavoritesWindowSize.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.myRef = React.createRef()
        this.unfavorite = this.unfavorite.bind(this);
        this.changeClubPageWindowSize = this.changeClubPageWindowSize.bind(this);
        this.openFiltersPopup = this.openFiltersPopup.bind(this);
        this.removeQuery = this.removeQuery.bind(this);
        this.clearTags = this.clearTags.bind(this)
        this.loaded = this.loaded.bind(this);
        this.reshuffleDeck = this.reshuffleDeck.bind(this);
        this.clearEverything = this.clearEverything.bind(this);
        this.getClubEvents = this.getClubEvents.bind(this);
        this.addToCal = this.addToCal.bind(this);
        this.heartFn = this.heartFn.bind(this);
        this.editMailingList = this.editMailingList.bind(this);

    }

    static filterItems = (data) => {
        for(var i = 0; i < data.length; ++i) {
            data[i].event.clubNames = [data[i].orgInfo.name];
        }
        return data;
    }

    componentDidMount() {
        if (this.props.token != null) {
            if (window.innerWidth <= 767) {
                this.setState({
                    spotlight: 62,
                });
            }

            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getEvents/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            // university: this.props.universityData.university,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    var events = data.sort((a,b) => a.time[0].toString().localeCompare(b.time[0].toString())); 
                    this.setState({
                        events: events,

                    });
                })
                .catch((error) => console.log(error));
                
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getTodaysClubEvents/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            // university: this.props.universityData.university,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => { 
                    //Discover.filterItems(data);
                    this.setState({
                        todaysEvents: Discover.filterItems(data)
                    }); 
                    
                })
                .catch((error) => console.log(error));

                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getRandomUUIDs/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        clubUUIDs: data.uuids
                    });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        
        if(this.props.universityData) {
            this.setState({
                tags: this.props.universityData.tags
            })
        }
        
    }

    loaded() {
        this.setState({
            reload: false,
        })
    }

    reshuffleDeck(){
        if (this.state.selected === -1) {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/resetPasses/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            tags: this.state.tags_selected,
                            query: this.state.searchQuery,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        clubUUIDs: data
                    });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        else {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/resetPasses/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            eventUUID: this.state.events[this.state.selected].uuid,
                            tags: this.state.tags_selected,
                            query: this.state.searchQuery,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        clubUUIDs: data
                    });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
    }

    removeTag(tag) {
        this.state.tags_selected.splice(this.state.tags_selected.indexOf(tag), 1);
        // this.changeEvent(this.state.selected);
        this.forceUpdate();
    }

    clearTags() {
        this.setState({
            tags_selected: [],
            reload: true,
        })

        // this.changeEvent(this.state.selected);
    }

    removeQuery() {
        this.setState({
            searchQuery: null,
        })
    }

    addTag(newTag) {
        this.state.tags_selected.push(newTag);
        // this.changeEvent(this.state.selected);
        this.setState({
            reload: true,
        })
    }

    hideSpotlight() {
        if (this.state.spotlight === 311) {
            this.setState({spotlight: 62});
        }
        else {
            this.setState({spotlight: 311})
        }
    }

    changeView() {
        if(this.state.view === "card") {
            this.setState({
                view: "grid"
            });
        }
        else {
            this.setState({
                view: "card"
            });
        }
    }

    changeEvent(index) {
        if (index === this.state.selected) {
            this.setState ({
                selected: -1,
            });
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getRandomUUIDs/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            tags: this.state.tags_selected,
                            query: this.state.searchQuery,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        clubUUIDs: data.uuids
                    });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
            this.myRef.current.scrollTo(0, 0);
            
        }
        else {
            this.setState ({
                selected: index,
                view: "grid",
            });
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getRandomUUIDs/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            eventUUID: this.state.events[index].uuid,
                            tags: this.state.tags_selected,
                            query: this.state.searchQuery,
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        clubUUIDs: data.uuids
                    });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
            this.myRef.current.scrollTo(0, 0);
            setTimeout(function(){
                this.setState({spotlight: 62})
            }.bind(this), 2000)
        }
        if(this.state.spotlight === 311 && window.innerWidth < 767) {
            this.setState({
                spotlight: 62,
            })
        }
    }

    setSearchQuery(query) {
        this.setState ({
            searchQuery: query,
        });
        if(window.innerWidth < 767) {
            if(this.state.favoritesWindowSize !== "off") {
                this.setState({
                    favoritesWindowSize: "off",
                })
            }
            if(this.state.clubPageWindowSize !== "off") {
                this.setState({
                    clubPageWindowSize: "off",
                })
            }
            if(this.state.spotlight !== 62) {
                this.setState({
                    spotlight: 62,
                })
            }
        }
        this.setState({
            view: "grid",
        })
        // this.changeEvent(this.state.selected);
    }

    changeFavoritesWindowSize(size) {
        this.setState({favoritesWindowSize: size});
    }

    openFiltersPopup(){
        if(this.state.filterOpen) {
            this.setState({
                filterOpen: false,
            })
        } else {
            this.setState({
                filterOpen: true,
            })
        }
    }
    

    changeClubPageWindowSize(size, info, favorited) {
        if (info != null) {
            info.favorited = favorited;
        }
        this.setState({clubPageWindowSize: size, clubInfo: info});
    }

    unfavorite(uuid) {
        this.setState({unfavoritedClub: uuid});
    }

    clearEverything() {
        this.setState({
            searchQuery: "", 
            view: "grid",
            selected: -1,
            tags_selected: [],
            reload: true,
            favoritesWindowSize: "off",
            clubPageWindowSize: "off",
            spotlight: 62,

        })
    }

    getClubEvents(clubUUID, callback) {
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getClubEvents/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken,
                        orgUUID: clubUUID
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    addToCal(eventUUID, zoom, callback) {
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getEventCalendar/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken,
                        eventUUID: eventUUID,
                        zoom: zoom,
                    }),
                }
            )
            .then((response) => {
                return response.blob()
            })
            .then((blob) => {
                callback(blob);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    heartFn(action, clubUUID, email, callback) {
        if (action === "unlike") {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                const date = new Date();
                window.ga('send', 'event', 'Club Interaction', 'Unfavorited');
                db.collection('logged_events').add({
                    'category': 'Club Interaction',
                    'action': 'Unfavorited',
                    'time': date,
                    'user': idToken,
                    'club': clubUUID,
                    'url': window.location.href,
                });
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "unlike"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "leave",
                            email: email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    callback();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        else {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                const date = new Date();
                window.ga('send', 'event', 'Club Interaction', 'Favorited');
                db.collection('logged_events').add({
                    'category': 'Club Interaction',
                    'action': 'Favorited',
                    'time': date,
                    'user': idToken,
                    'club': clubUUID,
                    'url': window.location.href,
                });
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "like",
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: clubUUID,
                            action: "join",
                            email: email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    callback();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
    }

    editMailingList(action, clubUUID, email, callback, updateLocally) {
        console.log("MAILING LIST", updateLocally);
        let actionString = "";
        if (action) {
            actionString = "join";
        }
        else {
            actionString = "leave";
        }
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken,
                        clubUUID: clubUUID,
                        email: email,
                        action: actionString,
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                callback(action);
                if (updateLocally == true) {
                    let clubInfoCopy = this.state.clubInfo;
                    if (action) {
                        clubInfoCopy.mailingList[firebase.auth().currentUser.uid] = email;
                    }
                    else {
                        delete clubInfoCopy.mailingList[firebase.auth().currentUser.uid];
                    }
                    this.setState({clubInfo: clubInfoCopy});
                }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    render() {
        if (this.props.token == null) {
            return (
                <Redirect to="/virtualfair/login" />
            );
        }
        //pracitce card mobile view
        /*if(window.innerWidth < 767) {
            return (
                <Grommet>
                <Box ref={this.myRef} style={{top: "100px", left: "0px", right: "0px", bottom: "0px", position: "absolute", overflowY: "scroll"}}>
                    <div id={styles.backdrop}></div>
                    {
                        (this.state.view === "card")?
                        <CardView 
                            color={this.props.universityData.primaryColor} 
                            changeView={this.changeView} 
                            eventUUID={(this.state.selected === -1) ? null : this.state.events[this.state.selected].uuid} 
                            width={this.state.spotlight} 
                            token={this.props.token} 
                            expandClub={(info, favorited) => this.changeClubPageWindowSize("on", info, favorited)}
                        />
                        :
                        <GridView 
                            color={this.props.universityData.primaryColor} 
                            changeView={this.changeView} 
                            eventUUID={(this.state.selected === -1) ? null : this.state.events[this.state.selected].uuid} 
                            width={this.state.spotlight} 
                            token={this.props.token}
                            universityData={this.props.universityData} 
                            addTag = {this.addTag}
                            removeTag = {this.removeTag}
                            tags = {this.state.tags}
                            tags_selected = {this.state.tags_selected}
                            unfavoritedClub = {this.state.unfavoritedClub}
                            query = {this.state.searchQuery}
                            expandClub={(info, favorited) => this.changeClubPageWindowSize("on", info, favorited)}
                        />
                    }
                </Box>
                <MainHeader 
                    setSearchQuery={this.setSearchQuery} 
                    openFavorites={() => this.changeFavoritesWindowSize("small")} 
                    universityData= {this.props.universityData} 
                    loginData = {this.props.loginData} 
                />
                
                <ClubPage
                    size={this.state.clubPageWindowSize}
                    changeWindowSize={this.changeClubPageWindowSize}
                    color={this.props.universityData.primaryColor}
                    info={this.state.clubInfo}
                    email={this.props.loginData.email}
                />
                <Spotlight 
                        width={this.state.spotlight} 
                        changeEvent={this.changeEvent} 
                        hideSpotlight={this.hideSpotlight} 
                        events={this.state.events} 
                        selected={this.state.selected}
                        token={this.props.token}
                        color={this.props.universityData.primaryColor}
                        imgTag={this.props.universityData.university}
                />
            </Grommet>
            )
        } else { */
        var spotlightWidth = this.state.spotlight;
        var spotlightBottom = "0px"
        if(window.innerWidth <= 767) {
            spotlightWidth = "100%";
            if(this.state.spotlight === 62) {
                spotlightBottom = "calc(100% - 150px)";
            }
        }
        
        return (
            <Grommet theme={this.state.theme} >
                <Box ref={this.myRef} style={{ top: "100px", left: "0px", right: "0px", bottom: "0px", position: "absolute", overflowX: "hidden", background: "#F4F4F4"}}>
                    {
                        (this.state.view === "card")?
                        <CardView 
                            color={this.props.universityData.primaryColor} 
                            changeView={this.changeView} 
                            clubUUIDs={this.state.clubUUIDs}
                            width={this.state.spotlight} 
                            token={this.props.token} 
                            expandClub={(info, favorited) => this.changeClubPageWindowSize("on", info, favorited)}
                            openFiltersPopup = {this.openFiltersPopup}
                            reshuffleDeck = {this.reshuffleDeck}
                            spotlight = {this.state.spotlight}
                        />
                        :
                        <GridView 
                            color={this.props.universityData.primaryColor} 
                            changeView={this.changeView} 
                            eventUUID={(this.state.selected === -1) ? null : this.state.events[this.state.selected].uuid} 
                            width={this.state.spotlight} 
                            token={this.props.token}
                            universityData={this.props.universityData} 
                            addTag = {this.addTag}
                            removeTag = {this.removeTag}
                            tags = {this.state.tags}
                            tags_selected = {this.state.tags_selected}
                            unfavoritedClub = {this.state.unfavoritedClub}
                            query = {this.state.searchQuery}
                            expandClub={(info, favorited) => this.changeClubPageWindowSize("on", info, favorited)}
                            favoritesWindowSize = {this.state.favoritesWindowSize}
                            clubPageWindowSize = {this.state.clubPageWindowSize}
                            openFiltersPopup = {this.openFiltersPopup}
                            removeQuery = {this.removeQuery}
                            clearTags = {this.clearTags}
                            reload = {this.state.reload}
                            loaded = {this.loaded}
                            eventSelected = {this.state.selected}
                            events = {this.state.events}
                            changeEvent={this.changeEvent} 
                            setSearchQuery={this.setSearchQuery} 
                            timezone={this.props.universityData.timezone}
                            dropdownState={this.state.dropdownState}
                            changeDropdown={(state) => this.setState({dropdownState: state})}
                            requestFeatures = {this.state.requestFeatures}
                        />
                    }
                </Box>
                <MainHeader 
                    setSearchQuery={this.setSearchQuery} 
                    changeFavoritesWindowSize = {this.changeFavoritesWindowSize} 
                    favoritesWindowSize = {this.state.favoritesWindowSize}
                    closeClubpage={() => this.changeClubPageWindowSize("off", null, null)}
                    universityData= {this.props.universityData} 
                    loginData = {this.props.loginData} 
                    logo = {this.props.universityData.logos[0]}
                    secondary_logo = {this.props.universityData.logos[2]}
                    openFiltersPopup = {this.openFiltersPopup}
                    query = {this.state.searchQuery}
                    clearEverything = {this.clearEverything}

                />
                <Box style={{top: "100px", left: "0px", width: spotlightWidth, bottom: spotlightBottom, position: "absolute", overflowX: "hidden",}}>
                    <Spotlight 
                        width={this.state.spotlight} 
                        changeEvent={this.changeEvent} 
                        hideSpotlight={this.hideSpotlight} 
                        events={this.state.events} 
                        selected={this.state.selected}
                        token={this.props.token}
                        color={this.props.universityData.primaryColor}
                        logos={this.props.universityData.logos}
                        timezone={this.props.universityData.timezone}
                        openClubPage={this.changeClubPageWindowSize}
                        todaysEvents={this.state.todaysEvents}
                    />
                </Box>
                <Favorites 
                    signOut={this.props.signOut} 
                    token={this.props.token} 
                    color={this.props.universityData.primaryColor} 
                    size={this.state.favoritesWindowSize} 
                    changeWindowSize={this.changeFavoritesWindowSize} 
                    loginData={this.props.loginData} 
                    unfavorite={this.unfavorite}
                    expandClub={(info, favorited) => this.changeClubPageWindowSize("on", info, favorited)}
                />
                <ClubPage
                    size={this.state.clubPageWindowSize}
                    changeWindowSize={this.changeClubPageWindowSize}
                    color="#2B3443"
                    info={this.state.clubInfo}
                    email={this.props.loginData.email}
                    token={this.props.token}
                    events={this.state.events}
                    timezone = {this.props.universityData.timezone}
                    authenticator = {this.props.universityData.authenticator}
                    getClubEvents = {this.getClubEvents}
                    addToCal = {this.addToCal}
                    heartFn = {this.heartFn}
                    editMailingList = {this.editMailingList}
                />
                <FilterPopup
                    open = {this.openFiltersPopup}
                    isOpen = {this.state.filterOpen}
                    addTag = {this.addTag}
                    removeTag = {this.removeTag}
                    tags = {this.state.tags}
                    tags_selected = {this.state.tags_selected}
                    colors = {this.state.colors}
                    clearTags = {this.clearTags}
                    query = {this.state.searchQuery}
                    removeQuery = {this.removeQuery}
                    color = {this.props.universityData.primaryColor}
                    dropdownState={this.state.dropdownState}
                    changeDropdown={(state) => this.setState({dropdownState: state})}
                />
                {
                    this.props.newUser && this.state.onboardingState !== 4 ? 
                    <OnboardingPopup 
                        onboardingState={this.state.onboardingState} 
                        advance={() => {
                            if (this.state.onboardingState == 1) {
                                this.changeView();
                            }
                            this.setState({onboardingState: this.state.onboardingState + 1})
                        }} 
                        end={() => this.setState({onboardingState: 4})} 
                        universityData={this.props.universityData}
                    />
                    :
                    ""
                }
            </Grommet>
        );
                //}
    }
}
export default Discover;
