import React from 'react';
import styles from './SearchBar.module.css';

import {TextInput} from 'grommet';


class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
        };
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
    
    }

    
    componentDidUpdate(prevProps) {
        if(window.innerWidth < 767) {
            if(this.props.query === "" && prevProps.query !== "") {
                this.setValue("");
            }
        }
    }
    

    setValue(value) {
        this.setState({
            value: value,
        })
    }

    search(event) {
        if(event.keyCode === 13) {
            this.props.setSearchQuery(event.target.value);
            if(window.innerWidth >= 767) {
                this.setValue("");
            }
        }
        if(event.keyCode === 8 && event.target.value === "") {
            this.props.setSearchQuery(event.target.value);
        }
    }

    

    render(){
       return(
            <TextInput className = {styles.outerSearch} style = {{background: '#EBEBEB', borderRadius: '8px', borderColor: '#00000000', fontFamily: 'SF Pro Display', fontSize: '14px'}}
            //can put drop downs easily in here
                placeholder= "Search"
                value={this.state.value}
                onChange={event => this.setValue(event.target.value)}
                onKeyUp = {this.search}
                // icon = {<img src = {this.props.search} alt = "search" id = {styles.search} style = {{zIndex: 100}}/>}


            />
       )
    }
}

export default SearchBar;