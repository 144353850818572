import React from 'react';
import styles from './EventCard.module.css';
import { Grid, Box } from 'grommet';
import * as staticImages from '../../staticImages';

import FormatDate from './FormatDate';
import Live from './Live';
import ClubPreview from './ClubPreview';
import * as firebase from 'firebase/app';

import { db } from '../../index.js';


class EventCard extends React.Component {
    logEventClick = () => {
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            const event =  this.props.event.uuid ?? 'unknown';
            window.ga('send', 'event', 'Spotlight Event Interaction', 'Clicked Event');
            db.collection('logged_events').add({
                'category': 'Spotlight Event Interaction',
                'action': 'Clicked Event',
                'time': date,
                'user': userID,
                'event': event,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Swipe View Interaction',
                'view_mode': 'Swipe',
                'time': date,
                'url': window.location.href,
            });
        }
    }

    render() {
        var color = "#F4F4FF";
        var width = "255px";
        if (this.props.selected) {
            color = "#FEF7EF";
        }
        if (window.innerWidth <= 767) {
            width = "100%"
        }
        return (
            <Grid
                onClick={() => {this.logEventClick(); this.props.changeEvent(this.props.index);}}
                id={styles.card_div} style={{background: color, height:'auto', width: width}}
                rows={['21px', 'auto', '10px', 'auto', '75px']}
                columns={['85%']}                    
                gap="none"
                areas={[
                    { name: 'title', start: [0, 1], end: [0, 1] },
                    { name: 'time', start: [0, 2], end: [0, 2] },
                    { name: 'description', start: [0, 3], end: [0, 3] },
                    { name: 'clubs', start: [0, 4], end: [0, 4] },
                ]}
            >
                <Box gridArea='title' id={styles.event_title}>{this.props.event.name}</Box>
                <Box gridArea='time' id={styles.event_time}>
                    <FormatDate
                        timezone = {this.props.timezone}
                        start = {this.props.start}
                        end = {this.props.end}
                        size = "10px"
                        color = "#979797"
                    />
                </Box>
                <Box gridArea='description' id={styles.event_description}>{this.props.description}</Box>
                <div id={styles.live_div}>
                    <Live 
                        start = {this.props.start}
                        end = {this.props.end}
                    />
                </div>
                <Box gridArea='clubs'>
                    <ClubPreview clubs = {this.props.event.clubs} images = {this.props.images} event = {this.props.event}/>
                </Box>
                <div id={styles.event_button}>
                    <img id={styles.arrow}alt="arrow" src={staticImages.ARROW} />
                </div>
            </Grid>
        );
    }
}   

export default EventCard;