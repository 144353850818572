import React from 'react';
import * as staticImages from '../../staticImages';
import styles from './Mobile.module.css';

class Mobile extends React.Component {
    render() {
        if(window.innerWidth >= 400) {
            return(
                <div>
                    <img id={styles.login_img} alt = "Login" src={staticImages.LOGIN}/>
                    <div id={styles.shadow}/> 
                    <div id={styles.inner_div}>
                        <img id={styles.mobile_logo} alt="Uniflow Logo" src={staticImages.UNIFLOW} />
                        <div id={styles.mobile_club_leader}>STUDENT</div>
                    </div>
                    <div id={styles.mobile_div}>
                        <div id={styles.description}>Please open in your browser to view this site</div>
                        <img id={styles.desktop_open} src={staticImages.OPEN_DESKTOP} alt="open desktop icon"/>
                    </div>
                </div>
            );
        }
        else {
            return(
                <div>
                    <img id={styles.login_img} alt = "Login" src={staticImages.LOGIN}/>
                    <div id={styles.shadow}/> 
                    <div id={styles.inner_div_small}>
                        <img id={styles.mobile_logo_small} alt="Uniflow Logo" src={staticImages.UNIFLOW} />
                        <div id={styles.mobile_club_leader_small}>STUDENT</div>
                    </div>
                    <div id={styles.mobile_div_small} >
                        <div id={styles.description} style={{fontSize: "17px"}}>Please open in your browser to view this site</div>
                        <img id={styles.desktop_open} src={staticImages.OPEN_DESKTOP} alt="open desktop icon"/>
                    </div>
                </div>
            );
        }
    }
}

export default Mobile;