import React from 'react';
import styles from './Favorites.module.css';
import { Box, Layer, Image } from 'grommet';
import * as staticImages from '../../staticImages';
import * as firebase from 'firebase/app';
import { db } from '../../index.js';

import ProfileInfo from './ProfileInfo';
import GridCard from './GridCard';

class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lighterColor: null,
            favorites: [],
            size: "off"
        };
        let lighterColor = "#";
        lighterColor += Math.min((parseInt(this.props.color.slice(1, 3), 16) + 96), 255).toString(16);
        lighterColor += Math.min((parseInt(this.props.color.slice(3, 5), 16) + 96), 255).toString(16);
        lighterColor += Math.min((parseInt(this.props.color.slice(5, 7), 16) + 96), 255).toString(16);
        this.state.lighterColor = lighterColor;

        this.unfavorite = this.unfavorite.bind(this);
        this.clearFavorites = this.clearFavorites.bind(this);
    }

    updateFavorites() {
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getFavorites/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                this.setState({
                    favorites: data
                });
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
        })
        .catch((error) => console.log(error));
    }

    unfavorite(uuid) {
        for (let i = 0; i < this.state.favorites.length; i++) {
            if (this.state.favorites[i].uuid === uuid) {
                this.state.favorites.splice(i, 1);
                break;
            }
        }
        this.props.unfavorite(uuid);
        //this.forceUpdate();
    }

    clearFavorites() {
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            window.ga('send', 'event', 'Favorites Interaction', 'Clicked Clear Favorites');
            db.collection('logged_events').add({
                'category': 'Favorites Interaction',
                'action': 'Clicked Clear Favorites',
                'time': date,
                'user': userID,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Clicked Clear Favorites',
                'time': date,
                'url': window.location.href,
            });
        }
        this.setState({favorites: []});
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/clearFavorites/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    render() {
        if (this.props.size === "off") {
            this.state.size = "off"
            return (
                <div></div>
            );
        }
        else if (window.innerWidth <= 767) {
            if (this.state.size === "off") {
                this.state.size = "on";
                this.updateFavorites();
            }
            return (
                <Box background={this.props.color} className={styles.outerDivMobile} style={{backgroundImage: `linear-gradient(to right, ${this.props.color}, ${this.state.lighterColor})`}}>
                    <Box className={styles.scrollBox} animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}}>
                        <Image src={staticImages.CLOSE} className={styles.closeImg} onClick={() => this.props.changeWindowSize("off")} />
                        <ProfileInfo signOut={this.props.signOut} name={this.props.loginData.displayName} style={{margin: "40px 60px 20px 35px", position: "relative"}} profilePicture={this.props.loginData.photoURL} />
                        <div className={styles.yourFavoritesMobile}>Your Favorites</div>
                        {this.state.favorites.map((info, index) =>
                            <GridCard info={info} color={this.props.color} heart={true} unfavorite={this.unfavorite} token={this.props.token} key={index} expandClub={this.props.expandClub} />
                        )}
                    </Box>
                    <Box direction="row" className={styles.clearDiv} onClick={this.clearFavorites}>
                        <img className={styles.clearImg} alt = "clear" src={staticImages.CLOSE} />
                        <div className={styles.clearText}>CLEAR ALL</div>
                    </Box>
                </Box>
            );
        }
        else if ((this.props.size === "small") || (window.innerWidth <= 1043)) {
            if (this.state.size === "off") {
                this.state.size = "on";
                this.updateFavorites();
            }
            return (
                <Layer responsive={false} onClickOutside={() => this.props.changeWindowSize("off")} full="vertical" position="right">
                    <Box background={this.props.color} width="medium" height="full" style={{backgroundImage: `linear-gradient(to right, ${this.props.color}, ${this.state.lighterColor})`}}>
                        <Image className={styles.closeImg} src={staticImages.CLOSE} onClick={() => this.props.changeWindowSize("off")} />
                        <ProfileInfo signOut={this.props.signOut} name={this.props.loginData.displayName} style={{margin: "40px 60px 20px 35px", position: "relative"}} profilePicture={this.props.loginData.photoURL} />
                        <div className={styles.yourFavorites}>Your Favorites</div>
                        <Box className={styles.scrollBox} animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}}>
                            {this.state.favorites.map((info, index) =>
                                <GridCard info={info} color={this.props.color} heart={true} unfavorite={this.unfavorite} token={this.props.token} key={index} expandClub={this.props.expandClub} />
                            )}
                        </Box>
                        <Box direction="row" className={styles.clearDiv} onClick={this.clearFavorites}>
                            <img className={styles.clearImg} alt = "clear" src={staticImages.CLOSE} />
                            <div className={styles.clearText}>CLEAR ALL</div>
                        </Box>
                    </Box>
                    {
                        (window.innerWidth >= 1044) ?
                        <Image src={staticImages.CHEVRON_LEFT} className={styles.expandImg} onClick={() => this.props.changeWindowSize("large")} />
                        :
                        ""
                    }
                </Layer>
            );
        }
        else if (this.props.size === "large") {
            return (
                <Layer onClickOutside={() => this.props.changeWindowSize("off")} full="vertical" position="right">
                    <Box background={this.props.color} width={`${Math.min(window.innerWidth - 300, 1200)}px`} height="full" style={{backgroundImage: `linear-gradient(to right, ${this.props.color}, ${this.state.lighterColor})`}}>
                        <Image className={styles.closeImg} src={staticImages.CLOSE} onClick={() => this.props.changeWindowSize("off")} />
                        <Box direction="row" style={{position: "relative", marginTop: "80px"}}>
                            <div className={styles.yourFavoritesExpanded}>Your Favorites</div>
                            <ProfileInfo signOut={this.props.signOut} name={this.props.loginData.displayName} style={{position: "absolute", right: "80px"}} profilePicture={this.props.loginData.photoURL} />
                        </Box>
                        <Box id={styles.gridView} animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}}>
                            {this.state.favorites.map((info, index) =>
                                <GridCard info={info} color={this.props.color} heart={true} unfavorite={this.unfavorite} token={this.props.token} key={index} expandClub={this.props.expandClub} />
                            )}
                        </Box>
                        <Box direction="row" className={styles.clearDiv} onClick={this.clearFavorites}>
                            <img className={styles.clearImg} alt = "clear" src={staticImages.CLOSE} />
                            <div className={styles.clearText}>CLEAR ALL</div>
                        </Box>
                    </Box>
                    <Image src={staticImages.CHEVRON_LEFT} className={styles.contractImg} onClick={() => this.props.changeWindowSize("small")} />
                </Layer>
            );
        }
    }
}
export default Favorites;