import React from 'react';
import styles from './LiveGridCard.module.css';
import * as staticImages from '../../staticImages';
import * as firebase from 'firebase/app';
import { db } from '../../index.js';

class LiveGridCard extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            type: "",
        };
        this.handleNav = this.handleNav.bind(this);
        this.getType = this.getType.bind(this);
    }

    componentDidMount() {
        this.getType();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.zoom !== this.props.zoom) {
            this.getType();
        }
    }

    getType() {
        if(this.props.zoom.toLowerCase().includes("zoom")) {
            this.setState({type: "zoom"});
        }
        else if(this.props.zoom.toLowerCase().includes("skype")) {
            this.setState({type: "skype"});
        }
        else if(this.props.zoom.toLowerCase().includes("teams") || this.props.zoom.toLowerCase().includes("microsoft")) {
            this.setState({type: "teams"});
        }
        else if(this.props.zoom.toLowerCase().includes("bluejeans")) {
            this.setState({type: "blue"});
        }
        else if(this.props.zoom.toLowerCase().includes("google") || this.props.zoom.toLowerCase().includes("meet")){
            this.setState({type: "meet"});
        }
    }

    handleNav() {
        if(this.props.zoom.toLowerCase().includes("https://")) {
            window.open( this.props.zoom, "_blank");
        }
        else {
            window.open( `https://${this.props.zoom}`, "_blank");
        }

        // log event to GA and Firebase
        const userID = firebase.auth().currentUser.uid;
        const date = new Date();
        try {
            const link = this.props.zoom ?? 'unknown';
            window.ga('send', 'event', 'Live Club/Event Interaction', 'Join Live Club');
            db.collection('logged_events').add({
                'category': 'Live Club/Event Interaction',
                'action': 'Join Live Club',
                'view_mode': 'unknown',
                'time': date,
                'user': userID,
                'link': link,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Join Live Club',
                'view_mode': 'unknown',
                'time': date,
                'url': window.location.href,
            });
        }
    }

    updateMailingList() {
        firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.props.clubUUID,
                            action: this.props.joined ? "leave" : "join",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.props.addToLocalMailingList(this.props.joined ? "leave" : "join", this.props.clubUUID, firebase.auth().currentUser.uid, firebase.auth().currentUser.email);
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }

    render() {
        switch(this.state.type) {
            case "zoom": 
                return(
                    <div id={styles.outer_div}>
                        <div id={styles.join}>JOIN LIVE MEETING</div>
                        <div id={styles.zoom_box} onClick={this.handleNav}>
                            <div id={styles.zoom}>Join</div>
                            <img id={styles.zoom_icon} src={staticImages.ZOOM} alt="zoom icon" />
                        </div>
                    </div>
                );    
            case "skype":
                return(
                    <div id={styles.outer_div}>
                        <div id={styles.join}>JOIN LIVE MEETING</div>
                        <div id={styles.skype_box} onClick={this.handleNav}>
                            <div id={styles.zoom}>Join</div>
                            <img id={styles.skype_icon} src={staticImages.SKYPE} alt="skype icon" />
                        </div>
                    </div>
                ); 
            case "teams":
                return(
                    <div id={styles.outer_div}>
                        <div id={styles.join}>JOIN LIVE MEETING</div>
                        <div id={styles.teams_box} onClick={this.handleNav}>
                            <div id={styles.zoom}>Join</div>
                            <img id={styles.teams_icon} src={staticImages.TEAMS} alt="teams icon" />
                        </div>
                    </div>
                );
            case "blue":
                return(
                    <div id={styles.outer_div}>
                        <div id={styles.join}>JOIN LIVE MEETING</div>    
                        <div id={styles.blue_box} onClick={this.handleNav}>
                            <div id={styles.zoom}>Join</div>
                            <img id={styles.blue_icon} src={staticImages.BLUEJEANS} alt="bluejeans icon" />
                        </div>
                    </div>
                ); 
            case "meet":
                return(
                    <div id={styles.outer_div}>
                        <div id={styles.join}>JOIN LIVE MEETING</div>    
                        <div id={styles.meet_box} onClick={this.handleNav}>
                            <div id={styles.zoom}>Join</div>
                            <img id={styles.meet_icon} src={staticImages.MEET} alt="meet icon" />
                        </div>
                    </div>
                );              

        }
            
        return(
            <div id={styles.outer_div}>
                <div id={styles.join}>JOIN LIVE MEETING</div>
                <div id={styles.other_box} onClick={this.handleNav}>
                    <div id={styles.other}>Join Us</div>
                </div>
            </div>
        );
    }
}

export default LiveGridCard;