import React from 'react';
import styles from './ContentDesktop.module.css';
import { Box, Image } from 'grommet';
import { Textfit } from 'react-textfit';
import * as staticImges from '../../staticImages';

class ContentDesktop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            image: null
        }
    }

    componentDidMount() {
        if (this.props.type === "events") {
            this.setState({
                title: "Events", 
                content: "Participate in events and meet the clubs (virtually) through video conferencing",
                image: staticImges.LOGIN_EVENTS
            })
        }
        else if (this.props.type === "discover") {
            this.setState({
                title: "Discover",
                content: "Swipe through dozens of clubs and learn more about the ones you are interested in through club pages",
                image: staticImges.LOGIN_DISCOVER
            })
        }
        else if (this.props.type === "join") {
            this.setState({
                title: "Join", 
                content: "Sign up for email lists, attend Info Sessions, check out club calendars, and see everything you need to join clubs",
                image: staticImges.LOGIN_JOIN
            })
        }
    }
    render() {
        return (
            <Box direction="row" gap="40px" className={styles.outerDiv}>
                <Image className={styles.image} src={this.state.image} />
                <Box className={styles.innerDiv} gap="5px">
                    <div className={styles.title}>{this.state.title}</div>
                    <div className={styles.contentDiv}>
                        {this.state.content}
                    </div>
                </Box>
            </Box>
        );
    }
}
export default ContentDesktop;