import React from 'react';
import styles from './DropdownItem.module.css';

import * as staticImages from '../../staticImages';

class DropdownItem extends React.Component {
    render() {
        let img, string, color;
        if (this.props.type === "category") {
            if (this.props.state === "title") {
                img = staticImages.categorySort;
            }
            else {
                img = staticImages.categorySortGrey;
            }
            string = "SORT BY CATEGORY";
        }
        else if (this.props.type === "A-Z") {
            if (this.props.state === "title") {
                img = staticImages.AtoZ;
            }
            else {
                img = staticImages.AtoZGrey;
            }
            string = "SORT A-Z";
        }
        else if (this.props.type === "Z-A") {
            if (this.props.state === "title") {
                img = staticImages.ZtoA;
            }
            else {
                img = staticImages.ZtoAGrey;
            }
            string = "SORT Z-A";
        }

        if (this.props.state === "title") {
            color = "#2B3443";
        }
        else if (this.props.state === "menu") {
            color = "#AAAAAA";
        }

        let outerClassName;
        if (this.props.state === "title" && !this.props.dropdownOpen) {
            outerClassName = styles.outerDivRounded;
        }
        else {
            outerClassName = styles.outerDiv;
        }

        return (
            <div className={outerClassName} onClick={this.props.onClick}>
                <img src={img} alt="sorting mechanism" className={styles.img} />
                <div className={styles.text} style={{color: color}}>{string}</div>
            </div>
        )
    }
}
export default DropdownItem;