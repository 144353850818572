import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './fonts.css';
import * as firebase from 'firebase/app';
import CookieService from './CookieService';
import Login from './Login/Login';
import Discover from './Discover/Discover';
import Mobile from './Login/components/Mobile';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UID: null,
            universityData: null,
            loginData: null,
            loading: true,
            incognito: false,
            newUser: false
        }

        this.login = this.login.bind(this);
        this.signOut = this.signOut.bind(this);
        this.getUniversityData = this.getUniversityData.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.clearLoading = this.clearLoading.bind(this);
        window.addEventListener('resize', this.handleWindowResize)

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                let cookie = CookieService.get('session_expiration');
                if (cookie != undefined) {
                    this.setState({loginData: user, loading: true});
                    this.getUniversityData(user.uid);
                }
            }
            else {
                this.signOut()
            }
        });
    }

    login(individualLogin, authenticator) {
        var provider;
        if (authenticator === "google") {
            provider = new firebase.auth.GoogleAuthProvider();
        }
        else if (authenticator === "microsoft") {
            provider = new firebase.auth.OAuthProvider('microsoft.com');
        }
        if (individualLogin) {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                firebase.auth().signInWithPopup(provider).then((result) => {
                    // Removed logging of userID
                    // firebase.auth().currentUser.getIdToken()
                    // .then((idToken) => console.log(idToken))
                    // Set cookie for session expiration in 14 days
                    let date = new Date();
                    date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
                    const options = {path: '/', expires: date};
                    CookieService.remove('session_expiration');
                    CookieService.set('session_expiration', "", options);
                    const loggingTime = new Date();
                    try {
                        window.ga('send', 'event', 'Authentication', 'Login');
                        this.props.db.collection("logged_events").add({
                            'category': 'Authentication',
                            'action': 'Login',
                            'time': loggingTime,
                            'user': result.user.uid,
                            'url': window.location.href,
                        });
                    } catch (e) {
                        this.props.db.collection("logged_errors").add({
                            'action': 'Login',
                            'time': loggingTime,
                            'url': window.location.href,
                        });
                    }

                    // Get information associated with the user
                    this.setState({loginData: result.user})
                    this.getUniversityData(result.user.uid);
                }).catch((error) => {
                    if (error.code === "auth/popup-closed-by-user") {
                        this.setState({incognito: true});
                    }
                    console.log(error)
                });
            })
            .catch((error) => console.log(error));
        }
        else {
            console.log("Anonymous user")
            // Set cookie for session expiration in 14 days
            let date = new Date();
            date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
            const options = {path: '/', expires: date};
            CookieService.remove('session_expiration');
            CookieService.set('session_expiration', "", options);

            // Sign the user in anonymously 
            firebase.auth().signInAnonymously()
            .catch((error) => console.log(error));
            setTimeout(() => window.location.reload(false), 1000);
        }
    }

    signOut() {
        const idToken = this.state.UID;
        const date = new Date();
        this.setState({
            UID: null,
            universityData: null
        });
        CookieService.remove('session_expiration');

        try{
            window.ga('send', 'event', 'Authentication', 'Logout');
            this.props.db.collection("logged_events").add({
                'category': 'Authentication',
                'action': 'Logout',
                'time': date,
                'user': idToken,
                'url': window.location.href,
            });
        } catch (e) {
            this.props.db.collection("logged_errors").add({
                'action': 'Logout',
                'time': date,
                'url': window.location.href,
            });
        }
    }

    getUniversityData(UID) {
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getUniversityData/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken
                    }),
                }
            )
            .then((response) => {
                // if (response.status === 403) {
                //     fetch(
                //         'https://covid-response-tool.herokuapp.com/api/removeUser/', 
                //         {
                //             method: 'POST', 
                //             headers: {
                //                 'Content-Type': 'application/json',
                //             },
                //             body: JSON.stringify({
                //                 token: idToken
                //             }),
                //         }
                //     )
                //     .then((response) => {
                //         if (!response.ok) throw Error(response.statusText);
                //     })
                //     .catch((error) => console.log(error));
                // }
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                this.setState({
                    UID: UID,
                    universityData: data,
                    loading: false,
                    newUser: data.newUser
                });
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    handleWindowResize(event) {
        event.preventDefault();
        this.forceUpdate();
    }

    clearLoading() {
        this.setState({loading: false});
    }

    render() {
        if (this.state.loading) {
            setTimeout(this.clearLoading, 1000);
            return (
                <div />
            );
        }
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes("facebook") || 
            userAgent.includes("snapchat") ||
            userAgent.includes("instagram") ||
            userAgent.includes("tiktok")) {
                return (
                    <Mobile />
                );
            }
        return (
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/virtualfair" />} />
                <Route exact path="/virtualfair/login" render={() => <Login fb_storage={this.props.fb_storage} incognito={this.state.incognito} login={this.login} token={this.state.UID} />} />
                <Route exact path="/virtualfair" render={() => <Discover signOut={this.signOut} loginData = {this.state.loginData} fb_storage={this.props.fb_storage} token={this.state.UID} universityData={this.state.universityData} newUser={this.state.newUser} />} />
                <Route path="/" render={() => <Redirect to="/virtualfair/login" />} />
            </Switch>
        );
    }
}
export default App;