import React from 'react';
import styles from './OnboardingPopup.module.css';
import * as staticImages from '../../staticImages';

import Spotlight from './Spotlight';
import Card from './Card';
import Reactions from './Reactions';
import Filters from './Filters';
import GridComponent from './GridComponent';

import { Layer, Box } from 'grommet';

class OnboardingPopup extends React.Component {
    render() {
        let cardData = {
            0: ["Spotlight", "Jump into university wide and club events with just a top on a spotlight card.", "NEXT"],
            1: ["Grid Cards", "Click Grid from the toggle and explore club cards by category or alphabetically.", "DONE"],
            2: ["Club Card", "View a little bit about a club on your campus and click explore to visit their page for more info!", "NEXT"],
            3: ["Swipe Actions", "Swipe right or click the heart to like a club and subscribe to their mailing list. Swipe left to pass.", "NEXT"],
        }
        if (window.innerWidth >= 767) {
            if (this.props.onboardingState == 0) {
                let now = new Date();
                let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                let timestamp = startOfDay / 1000;
                return (
                    <Layer style={{background: "none"}} onEsc={this.props.end}>
                        <div style={{height: `${window.innerHeight}px`, width: `${window.innerWidth}px`, background: "none"}} onClick={this.props.advance}>
                            <div className={styles.outer1}>
                                <div className={styles.title1}>{cardData[this.props.onboardingState][0]}</div>
                                <div className={styles.desc1}>{cardData[this.props.onboardingState][1]}</div>
                                <div className={styles.next1} onClick={this.props.advance}>{cardData[this.props.onboardingState][2]}</div>
                                <img className={styles.next1img} src={staticImages.WHITE_ARROW} onClick={this.props.advance} alt="Skip step" />
                                <div className={styles.page1}>{this.props.onboardingState + 1}/4</div>
                            </div>
                            <Box className={styles.spotlight}>
                                <Spotlight 
                                    width={311} 
                                    changeEvent={() => {}} 
                                    hideSpotlight={() => {}} 
                                    events={[
                                        {
                                            name: "All-Club Fair",
                                            description: "All students are invited to come find new clubs to join!",
                                            time: [1599314400, 1599321600],
                                            clubs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                            clubNames: ["Ski Club", "Bird Watching Club", "Central Student Government"],
                                            images: [
                                                "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fa748f4da-de8e-11ea-8544-3e128e7e5bbc?alt=media",
                                                "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fa0e86dfa-de8e-11ea-8544-3e128e7e5bbc?alt=media",
                                                "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fad51da40-de8e-11ea-8544-3e128e7e5bbc?alt=media"
                                            ]
                                        }
                                    ]} 
                                    selected={-1}
                                    token=""
                                    color={this.props.universityData.primaryColor}
                                    logos={this.props.universityData.logos}
                                    timezone={this.props.universityData.timezone}
                                    openClubPage={() => {}}
                                    todaysEvents={[
                                        {
                                            event: {
                                                name: "Meet and Greet [Ski Club]",
                                                description: "We look forward to kicking off a great year of skiing!",
                                                time: [timestamp + 50400, timestamp + 50400 + 7200],
                                                clubs: [1],
                                                clubNames: ["Ski Club"]
                                            },
                                            orgInfo: {
                                                name: "Ski Club",
                                                album: ["https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fa748f4da-de8e-11ea-8544-3e128e7e5bbc?alt=media"]
                                            }
                                        }
                                    ]}
                                />
                            </Box>
                            <div className={styles.skipOnboarding} onClick={this.props.end}>SKIP ONBOARDING</div>
                        </div>
                    </Layer>
                );
            }
            else if (this.props.onboardingState == 2) {
                let height, top;
                if(window.innerHeight <= 824) {
                    height = "431px";
                    top = "calc((100% - 504px) / 2)";
                }
                else {
                    height = "510px";
                    top = "calc((100% - 583px) / 2)";
                }
                return (
                    <Layer style={{background: "none"}} onClickOutside={this.props.advance} onEsc={this.props.end}>
                        <div style={{height: `${window.innerHeight}px`, width: `${window.innerWidth}px`, background: "none"}} onClick={this.props.advance}>
                            <div className={styles.swipeCard}>
                                <Card 
                                    top="0px" 
                                    spotlight = {this.props.spotlight}
                                    likeButtonPressed = {() => {}} 
                                    dislikeButtonPressed = {() => {}} 
                                    currentClubInfo={{
                                        name: "Bird Watching Club",
                                        short_description: "Come spent some time outdoors with us and the birds!",
                                        logo: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fa1a0f910-de8e-11ea-bae4-3e128e7e5bbc?alt=media"
                                    }} 
                                    expandClub={() => {}} 
                                    color = {this.props.universityData.primaryColor}
                                    height = {height}
                                    top = {top}
                                />
                            </div>
                            
                            <div className={styles.outer2} style={{left: `${Math.max((window.innerWidth - 311) / 2 - 187 + 311 - 350, 0)}px`}}>
                                <div className={styles.title1}>{cardData[this.props.onboardingState][0]}</div>
                                <div className={styles.desc1}>{cardData[this.props.onboardingState][1]}</div>
                                <div className={styles.next1} onClick={this.props.advance}>{cardData[this.props.onboardingState][2]}</div>
                                <img className={styles.next1img} src={staticImages.WHITE_ARROW} onClick={this.props.advance} alt="Skip step" />
                                <div className={styles.page1}>{this.props.onboardingState + 1}/4</div>
                            </div>

                            <div className={styles.skipOnboarding} onClick={this.props.end}>SKIP ONBOARDING</div>
                        </div>
                    </Layer>
                );
            }
            else if (this.props.onboardingState == 3) {
                let height, top;
                if(window.innerHeight <= 824) {
                    height = "431px";
                    top = "calc((100% - 504px) / 2)";
                }
                else {
                    height = "510px";
                    top = "calc((100% - 583px) / 2)";
                }
                return (
                    <Layer style={{background: "none"}} onClickOutside={this.props.advance} onEsc={this.props.end}>
                        <div style={{height: `${window.innerHeight}px`, width: `${window.innerWidth}px`, background: "none"}} onClick={this.props.advance}>
                            <div className={styles.swipeCard}>
                                <Card 
                                    top="0px" 
                                    spotlight = {this.props.spotlight}
                                    likeButtonPressed = {() => {}} 
                                    dislikeButtonPressed = {() => {}} 
                                    currentClubInfo={{
                                        name: "Bird Watching Club",
                                        short_description: "Come spent some time outdoors with us and the birds!",
                                        logo: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2fa1a0f910-de8e-11ea-bae4-3e128e7e5bbc?alt=media"
                                    }} 
                                    expandClub={() => {}} 
                                    color = {this.props.universityData.primaryColor}
                                    height = {height}
                                    top = {top}
                                />
                                <Reactions 
                                    animate={false} 
                                    currentClubInfo="" 
                                    likeCallBack={() => {}} 
                                    dislikeCallBack={() => {}} 
                                    reverseCallBack={() => {}} 
                                /> 
                            </div>
                            
                            <div className={styles.outer3} style={{left: `${Math.max((window.innerWidth - 311) / 2 - 187 + 311 - 350, 0)}px`}}>
                                <div className={styles.title1}>{cardData[this.props.onboardingState][0]}</div>
                                <div className={styles.desc1}>{cardData[this.props.onboardingState][1]}</div>
                                <div className={styles.next1} onClick={this.props.advance}>{cardData[this.props.onboardingState][2]}</div>
                                <img className={styles.next1img} src={staticImages.WHITE_ARROW} onClick={this.props.advance} alt="Skip step" />
                                <div className={styles.page1}>{this.props.onboardingState + 1}/4</div>
                            </div>

                            <div className={styles.skipOnboarding} onClick={this.props.end}>SKIP ONBOARDING</div>
                        </div>
                    </Layer>
                );
            }
            else if (this.props.onboardingState == 1) {
                return (
                    <Layer style={{background: "none"}} onClickOutside={this.props.advance} onEsc={this.props.end}>
                        <div style={{height: `${window.innerHeight}px`, width: `${window.innerWidth}px`, background: "none"}} onClick={this.props.advance}>
                            
                            <div className={styles.filters}>
                                <Filters 
                                    tags = {[]}
                                    tags_selected = {[]}
                                    removeTag = {() => {}}
                                    addTag = {() => {}}
                                    color = {this.props.universityData.primaryColor}
                                    loadClubs = {() => {}}
                                    clearTags = {() => {}}
                                    openFiltersPopup = {() => {}}
                                    query = {null}
                                    removeQuery = {() => {}}
                                    dropdownState={"category"}
                                    changeDropdown={() => {}}
                                />
                                <GridComponent 
                                    top="229px" 
                                    clubs={{
                                        Academic: [
                                            {
                                                name: "Mathematics Club",
                                                short_description: "Are you interested in exploring math? We accept all years and abilities.",
                                                album: [
                                                    "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2Fmath.jpg?alt=media&token=739d3be2-dcbf-47f4-aa19-12a645065351"
                                                ]
                                            },
                                            {
                                                name: "Society of Engineers",
                                                short_description: "Come grow your engineering network! We have members in every engineering major on campus.",
                                                album: [
                                                    "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/OrganizationLogos%2Fengineer.jpg?alt=media&token=19714a85-3c29-4f3a-8e75-e9593701c990"
                                                ]
                                            },
                                        ]
                                    }} 
                                    state="category"
                                    view="desktop" 
                                    color = {this.props.universityData.primaryColor}
                                    expandClub = {() => {}}
                                    addToLocalMailingList = {() => {}}
                                />
                            </div>
                            
                            <div className={styles.outer4}>
                                <div className={styles.title1}>{cardData[this.props.onboardingState][0]}</div>
                                <div className={styles.desc1}>{cardData[this.props.onboardingState][1]}</div>
                                <div className={styles.next1} onClick={this.props.advance}>{cardData[this.props.onboardingState][2]}</div>
                                <img className={styles.next1img} src={staticImages.WHITE_ARROW} onClick={this.props.advance} alt="Skip step" />
                                <div className={styles.page1}>{this.props.onboardingState + 1}/4</div>
                            </div>

                            <div className={styles.skipOnboarding} onClick={this.props.end}>SKIP ONBOARDING</div>
                        </div>
                    </Layer>
                );
            }
            else {
                return (
                    <div></div>
                );
            }
        }
        else {
            return (
                <Layer style={{background: "none"}} onClickOutside={this.props.advance} onEsc={this.props.end}>
                    <div style={{height: `${window.innerHeight}px`, width: `${window.innerWidth}px`, background: "rgba(0, 0, 0, 0.8)"}} onClick={this.props.advance}>
                        <div className={styles.outerMobile}>
                            <div className={styles.title1}>{cardData[this.props.onboardingState][0]}</div>
                            <div className={styles.desc1}>{cardData[this.props.onboardingState][1]}</div>
                            <div className={styles.next1} onClick={this.props.advance}>{cardData[this.props.onboardingState][2]}</div>
                            <img className={styles.next1img} src={staticImages.WHITE_ARROW} onClick={this.props.advance} alt="Skip step" />
                            <div className={styles.page1}>{this.props.onboardingState + 1}/4</div>
                            <div className={styles.skipOnboardingMobile} onClick={this.props.end}>SKIP ONBOARDING</div>
                        </div>

                    </div>
                </Layer>
            );
        }
    }
}
export default OnboardingPopup;