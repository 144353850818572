import React from 'react';
import styles from './MainHeader.module.css';
import SearchBar from './components/SearchBar';
import Logo from './components/Logo';
import User from './components/User';
// import Favorites from './components/Favorites';

import { Grid, Box } from 'grommet';

class MainHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            universityData: this.props.universityData,
            loginData: this.props.loginData,
            color: this.props.universityData.primaryColor,
        }
    };

    componentDidMount () {
        
    }
    render() {
        if(window.innerWidth < 767) {
            return (
                <Box style={{top: "0px", left: "0px", height: "100px", right: "0px", position: "absolute"}} className = {styles.headerBorder}>
                <Grid
                    rows={['100px']}
                    columns={['83px', 'calc(100% - 188px)', '85px']}
                    gap="10px"
                    areas={[
                        { name: 'left', start: [0, 0], end: [0, 0] },
                        { name: 'middle', start: [1, 0], end: [1, 0] },
                        { name: 'right', start: [2, 0], end: [2, 0] },
                    ]}
                    pad="none"
                >
                    <Logo gridArea = "left" 
                        logo = {this.props.secondary_logo}
                        left = '15px'
                        small = {true}
                        clearEverything = {this.props.clearEverything}
                    />
                    <SearchBar gridArea = 'middle' 
                        setSearchQuery = {this.props.setSearchQuery}
                        query = {this.props.query}
                    />
                   
                    <User gridArea = 'right' 
                        photo = {this.props.loginData.photoURL}
                        changeFavoritesWindowSize = {this.props.changeFavoritesWindowSize}
                        favoritesWindowSize = {this.props.favoritesWindowSize}
                        size = "50px"
                        closeClubpage = {this.props.closeClubpage}
                        color = {this.state.color}
                    />
                </Grid>
            </Box>
            )
        } else {
        return (
            <Box style={{top: "0px", left: "0px", height: "100px", right: "0px", position: "absolute"}} className = {styles.headerBorder}>
                <Grid
                    rows={['100px']}
                    // columns={['325px', 'calc(100% - 635px)', '210px', '100px']}
                    columns={['325px', 'calc(100% - 455px)', '130px']}

                    gap="none"
                    areas={[
                        { name: 'left', start: [0, 0], end: [0, 0] },
                        { name: 'middle', start: [1, 0], end: [1, 0] },
                        // { name: 'right', start: [2, 0], end: [2, 0] },
                        { name: 'farRight', start: [3, 0], end: [3, 0] },
                    ]}
                    pad="none"
                >
                    <Logo gridArea = "left" 
                        logo = {this.props.logo}
                        left = '0px'
                        small = {false}
                        clearEverything = {this.props.clearEverything}

                    />
                    <SearchBar gridArea = 'middle' 
                        setSearchQuery = {this.props.setSearchQuery}
                    />
                    {/* <Favorites gridArea = 'right' 
                        color = {this.state.color}
                        changeFavoritesWindowSize = {this.props.changeFavoritesWindowSize}
                        openFiltersPopup = {this.props.openFiltersPopup}
                    /> */}
                    <User gridArea = 'farRight' 
                        photo = {this.props.loginData.photoURL}
                        changeFavoritesWindowSize = {this.props.changeFavoritesWindowSize}
                        favoritesWindowSize = {this.props.favoritesWindowSize}
                        size = '50px'
                        closeClubpage={() => {}}
                        color = {this.state.color}

                    />
                </Grid>
            </Box>
        );
        }
    }
}
export default MainHeader;