import React from 'react';
import styles from './FormatDate.module.css';
import * as moment from '../../../node_modules/moment-timezone';

import {Box } from 'grommet';

class FormatDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            time: "",
        }
    }

    componentDidMount() {
        if(this.props.start !== undefined && this.props.end !== undefined && this.props.timezone !== undefined) {
            this.formatDate();
            this.formatTime();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.start !== this.props.start || prevProps.end !== this.props.end) {
            this.formatDate();
            this.formatTime();
        }
    }
    
    formatDate() {
        var months = ["Jan. ", "Feb. ", "Mar. ", "Apr. ", "May ", "June ", "July ", "Aug. ", "Sept. ", "Oct. ", "Nov. ", "Dec. "]
        var m = moment(this.props.start*1000);
        var date = m.tz(this.props.timezone).format('ddd') + ", " + months[parseInt(m.tz(this.props.timezone).format('MM')) - 1] + " " + m.tz(this.props.timezone).format('DD');
        this.setState ({
            date: date,
        })
    }

    formatTime() {
        var start = moment(this.props.start*1000);
        var startMin = start.tz(this.props.timezone).format('mm');
        var startHr = start.tz(this.props.timezone).format('h');
        var startA = start.tz(this.props.timezone).format('A');
        var end = moment(this.props.end*1000);
        var endMin = end.tz(this.props.timezone).format('mm');
        var endHr = end.tz(this.props.timezone).format('h');
        var endA = end.tz(this.props.timezone).format('A z');
        var time = startHr;
        if(startMin !== "00") {
            time += ":" + startMin;
        }
        if(startA !== endA) {
            time += startA;
        }
        time += "-" + endHr;
        if(endMin !== "00") {
            time += ":" + endMin;
        }
        time += endA;
        
        this.setState({
            time: time,
            start: this.props.start,
            end: this.props.end,
        })
    }

    render() {
        return(
            <Box id={styles.event_time} style={{color: this.props.color, fontSize: this.props.size}}>{this.state.time} | {this.state.date}</Box>
        );
    }
}

export default FormatDate;