import React from 'react';
import styles from './Live.module.css';
import * as staticImages from '../../staticImages';

class Live extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            live: false,
            curTime: Math.round(Date.now() / 1000)
        }
    }
    componentDidMount() {
        if (this.props.start < this.state.curTime && this.state.curTime < this.props.end) {
            this.setState ({
                live: true
            })
        }
    }
    render() {
        return(
            <div>
            {
                (this.state.live)?
                <div id={styles.live_div}>
                    <img src={staticImages.BOLT} alt="bolt" id={styles.bolt}/>
                    <div id={styles.live}>LIVE</div>
                </div>
                :
                <div></div>
            }
            </div>
        );
    }
}

export default Live;