import React from 'react';
import styles from './RequestFeatures.module.css';
import * as staticImages from '../../staticImages';
import * as firebase from 'firebase/app';


import { Layer, Box } from 'grommet';

class RequestFeatures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFeatures: false,
        }
        this.submitFeature = this.submitFeature.bind(this);
        this.requestFeatures = this.requestFeatures.bind(this);
    }
    submitFeature() {
        this.requestFeatures()
        var request = document.getElementById("request").value
        if( request !== "") {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/featureRequest/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            request: request
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
    }
    requestFeatures() {
        if(this.state.requestFeatures) {
            this.setState({
                requestFeatures: false,
            })
        }
        else {
            this.setState({
                requestFeatures: true,
            })
        }
    }
    render() {
        if(this.state.requestFeatures) {
            return (
                <Layer style={{background: "none"}}  onClickOutside={this.requestFeatures} onEsc={this.requestFeatures}>
                    <div id={styles.outer} >
                        <img id={styles.exit} src={staticImages.GRAY_EXIT} alt="exit" onClick={this.requestFeatures}/>
                        <div id={styles.title}>Feature Request</div>
                        <div id={styles.description}>What would you like to see in UniFlow going forward?</div>
                        <div id={styles.text_box}> 
                            <textarea type="text" id="request" placeholder="I think it would be really cool if you added..." className={styles.text} />
                        </div>
                        <div id={styles.submit_button} onClick={this.submitFeature}>
                            <div id={styles.submit}>
                                Send Request
                            </div>
                        </div>
                    </div>
                </Layer>
            );
        }
        else {
            return (
                    <img src={staticImages.REQUEST} alt="request button" id={styles.request} onClick={this.requestFeatures}/>
            
            );
        }

    }
}
export default RequestFeatures;