import React from 'react';
import {  Avatar, Box } from 'grommet';
import styles from './User.module.css';
import * as staticImages from '../../staticImages';

function User({photo, changeFavoritesWindowSize, favoritesWindowSize, size, closeClubpage, color}){
    return (
        <Box direction="row"  gap="small" align = "center" justify = "center">
            {window.innerWidth <767 ?
                ""
                :
                <div className = {styles.line}/>
            }
            {favoritesWindowSize === "off" ?
                <div>
                { photo ?
                <Avatar onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}} src= {photo} style = {{width: size, height: size, cursor: 'pointer'}}/> 
                    :
                    <Avatar onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}} background="#C9D9E8" style = {{width: size, height: size, cursor: 'pointer'}}>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-person-64.png?alt=media&token=4b33bfa4-6bef-482e-a3c2-df4109be3124" style = {{width: '40px'}}/>
                    </Avatar>
                }
                {window.innerWidth <767 ?
                    <div className = {styles.circle2} style = {{background: color }}>
                        <img src = {staticImages.HEART_WHITE} alt = "heart" className = {styles.heart} onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}}/>
                    </div> 
                    :
                    <div className = {styles.circle}  style = {{background: color }}>
                        <img src = {staticImages.HEART_WHITE} alt = "heart" className = {styles.heart} onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}}/>
                    </div> 
                }
                    
                </div>
                :
                <div>
                { photo ?
                <Avatar onClick={() => {changeFavoritesWindowSize("off"); closeClubpage();}} src= {photo} style = {{width: size, height: size, cursor: 'pointer'}}/> 
                :
                <Avatar background="#C9D9E8" onClick={() => {changeFavoritesWindowSize("off"); closeClubpage();}} style = {{width: size, height: size, cursor: 'pointer'}}>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-person-64.png?alt=media&token=4b33bfa4-6bef-482e-a3c2-df4109be3124" style = {{width: '40px'}}/>
                </Avatar>
                }
                {window.innerWidth < 767 ?
                    <div className = {styles.circle2}  style = {{background: color }}>
                        <img src = {staticImages.HEART_WHITE} alt = "heart" className = {styles.heart} onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}}/>
                    </div> 
                    :
                    <div className = {styles.circle}  style = {{background: color }}>
                        <img src = {staticImages.HEART_WHITE} alt = "heart" className = {styles.heart} onClick={() => {changeFavoritesWindowSize("small"); closeClubpage();}}/>
                    </div> 
                } 
                </div>               
            }

        </Box>
    )
}

export default User;