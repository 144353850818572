import React from 'react';
import styles from './SwipeGridToggle.module.css';
import {  Box } from 'grommet';
import * as staticImages from '../../staticImages';


function SwipeGridToggle({swipe, changeView, color, openFiltersPopup, setSearchQuery, query}) {
    if(window.innerWidth <= 767) {
        var width = "308px";
        var left = "calc(50% - 175px)";
        if(window.innerWidth < 414) {
            width = "calc(100% - 106px)";
            left = "27px";
        }
        if(query !== undefined && query !== null && query !== "") {
            return (
                <Box id={styles.clear_div} style={{left: left, width: width, top: "66px"}}> 
                    <div id={styles.clear_search} onClick = {() => setSearchQuery("")}>CLEAR SEARCH</div>
                    <div id = {styles.filterToggle_clear} style = {{background: color}} onClick = {openFiltersPopup}>
                        <img src = {staticImages.FILTER} alt = "filter" id = {styles.filterIcon}/>
                    </div>
                </Box>
            );
        }
        return(
            <Box id={styles.mobile_div} style={{left: left, width: width, border: "2px solid " + color}}>
                <div id={styles.clickable} onClick={changeView}>
                {
                    (!swipe) ? 
                    <div>
                        <div id={styles.thumb_swipe}></div>
                        <div id={styles.swipe_mobile} style={{color: "white", fontSize: "12px"}}>Grid</div>
                        <div id={styles.grid_mobile} style={{fontSize: "12px"}}>Swipe</div>
                    </div>
                    :
                    <div>
                        <div id={styles.thumb_grid}></div>
                        <div id={styles.swipe_mobile} style={{fontSize: "12px"}}>Grid</div>
                        <div id={styles.grid_mobile} style={{color: "white", fontSize: "12px"}}>Swipe</div>
                    </div>
                }
                </div>
                <div id = {styles.filterToggle} style = {{background: color}} onClick = {openFiltersPopup}>
                    <img src = {staticImages.filterSort} alt = "filter" id = {styles.filterIcon}/>
                </div>
            </Box>
        );
    }
    return (
        <Box id={styles.swipegridtoggle} style={{border: "2px solid " + color}}>
            <div id={styles.clickable} onClick={changeView}>
            {
                (!swipe)? 
                <div>
                    <div id={styles.thumb_swipe}></div>
                    <div id={styles.swipe} style={{color: "white"}}>Grid</div>
                    <div id={styles.grid}>Swipe</div>
                </div>
                :
                <div>
                    <div id={styles.thumb_grid}></div>
                    <div id={styles.swipe}>Grid</div>
                    <div id={styles.grid} style={{color: "white"}}>Swipe</div>
                </div>
                
            }
            </div>
            
        </Box>
    );
}
export default SwipeGridToggle;