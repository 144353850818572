import React from 'react';
import SwipeGridToggle from './SwipeGridToggle';
import Card from './Card';
import styles from './CardView.module.css';
// import logo from './logosample.png';
import Reactions from './Reactions';
import * as firebase from 'firebase/app';

import { db } from '../../index.js';

import {Box} from 'grommet';
import NoMoreClubs from './NoMoreClubs';
import ConfirmationThrow from './ConfirmationThrow';
import RequestFeatures from './RequestFeatures';

class CardView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clubUUIDs: null,
            currentClubInfo: null,
            nextClubInfo: null,
            index: 0,
            done: false,
            animate: false,
            nextDone: false,
            confirmation: null,
            disliked: false,
        };

        this.likeButtonPressed = this.likeButtonPressed.bind(this);
        this.reverseButtonPressed = this.reverseButtonPressed.bind(this);
        this.dislikeButtonPressed = this.dislikeButtonPressed.bind(this);
        this.getNextClubInfo = this.getNextClubInfo.bind(this);
        this.getClubInfo = this.getClubInfo.bind(this);
        this.resetDisliked = this.resetDisliked.bind(this);
    }

    likeButtonPressed(){
        if(this.state.index === this.state.clubUUIDs.length - 1){
            this.setState({
                done: true,
            });
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[this.state.index],
                            action: "like"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[this.state.index],
                            action: "join",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        else{
            var next = this.state.index;
            this.setState({
                currentClubInfo: this.state.nextClubInfo,
            });
            this.state.index++;
            this.getNextClubInfo();
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[next],
                            action: "like"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[next],
                            action: "join",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            const club = this.state.clubUUIDs[this.state.index] ?? 'unknown';
            window.ga('send', 'event', 'Swipe View Interaction', 'Liked');
            db.collection('logged_events').add({
                'category': 'Swipe View Interaction',
                'action': 'Liked',
                'time': date,
                'user': userID,
                'club': club,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Swipe View Interaction',
                'view_mode': 'Swipe',
                'time': date,
                'url': window.location.href,
            });
        }
        this.setState({animate: true, confirmation: "like"});
    }

    dislikeButtonPressed(){
        if(this.state.index === this.state.clubUUIDs.length - 1){
            this.setState({
                done: true,
                disliked: true,
            });
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[this.state.index],
                            action: "pass"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[this.state.index],
                            action: "leave",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        else{
            var next = this.state.index;
            this.setState({
                currentClubInfo: this.state.nextClubInfo,
                disliked: true,
            });
            this.state.index++;
            this.getNextClubInfo();
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/reactToClub/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[next],
                            action: "pass"
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                })
                .catch((error) => console.log(error));
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/editMailingList/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[next],
                            action: "leave",
                            email: firebase.auth().currentUser.email
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {

                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            const club = this.state.clubUUIDs[this.state.index] ?? 'unknown';
            window.ga('send', 'event', 'Swipe View Interaction', 'Disliked');
            db.collection('logged_events').add({
                'category': 'Swipe View Interaction',
                'action': 'Disliked',
                'time': date,
                'user': userID,
                'club': club,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Swipe View Interaction',
                'view_mode': 'Swipe',
                'time': date,
                'url': window.location.href,
            });
        }
        this.setState({animate: false, confirmation: "pass"});
    }

    resetDisliked(){
        this.setState({disliked: false})
    }

    resetDisliked(){
        this.setState({disliked: false})
    }

    reverseButtonPressed(){
        if(this.state.done){
            this.setState({
                done: false
            });
            this.getClubInfo();
        }
        else{
            this.state.index = Math.max(this.state.index - 1, 0);
            this.setState({nextClubInfo: this.state.currentClubInfo})
            this.getClubInfo();
        }
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            window.ga('send', 'event', 'Swipe View Interaction', 'Reversed');
            db.collection('logged_events').add({
                'category': 'Swipe View Interaction',
                'action': 'Reversed',
                'time': date,
                'user': userID,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Swipe View Interaction',
                'view_mode': 'Swipe',
                'time': date,
                'url': window.location.href,
            });
        }
        this.setState({animate: false, confirmation: "replay"});
    }

    getNextClubInfo() {
        if(this.state.index === this.state.clubUUIDs.length -1) {
            this.setState({
                nextClubInfo: null,
            })
        } 
        else {
            firebase.auth().currentUser.getIdToken()
            .then((idToken) =>{
                fetch(
                    'https://covid-response-tool.herokuapp.com/api/getClubInfo/', 
                    {
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: idToken,
                            clubUUID: this.state.clubUUIDs[this.state.index + 1]
                        }),
                    }
                )
                .then((response) => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        nextClubInfo: data
                    })
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
    }

    getClubInfo(){
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getClubInfo/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken,
                        clubUUID: this.state.clubUUIDs[this.state.index]
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                this.setState({
                    currentClubInfo: data
                });
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    render() {
        if(this.props.clubUUIDs !== this.state.clubUUIDs){
            this.state.clubUUIDs = this.props.clubUUIDs;
            this.state.index = 0;
            this.state.done = false;
            if(this.props.clubUUIDs.length === 0){
                this.state.done = true;
            }
            else{
                this.getNextClubInfo();
                this.getClubInfo();
            }
        }

        if(window.innerWidth < 767) {
            if(this.props.width !== 62){
                return(<div></div>);
            }
            return (
            <Box id={styles.cardView} style={{width: '100%'}}>
                <SwipeGridToggle 
                    swipe={true} 
                    color={this.props.color} 
                    changeView={this.props.changeView} 
                    openFiltersPopup = {this.props.openFiltersPopup}
                    query={this.props.query}
                />
                {
                    this.state.done? 
                    <div id = {styles.mobile_div} >
                        <NoMoreClubs 
                            card = {false} 
                            clearTags={this.props.clearTags} 
                            style = {{height: '100%', top: '0px'}} 
                            reshuffleDeck = {this.props.reshuffleDeck}
                            disliked = {this.state.disliked}
                            resetDisliked = {this.resetDisliked}
                        /> 
                    </div>
                    : 
                    <div id = {styles.mobile_div} >
                        { 
                            this.state.nextClubInfo ?
                            <Card 
                                top="10px" 
                                spotlight = {this.props.spotlight}  
                                likeButtonPressed = {this.likeButtonPressed} 
                                dislikeButtonPressed = {this.dislikeButtonPressed} 
                                currentClubInfo={this.state.nextClubInfo} 
                                expandClub={this.props.expandClub} 
                                color = {this.props.color}
                            />
                            :
        
                            <NoMoreClubs card = {true} style = {{top: '-10px', height: '100%'}} style2 = {{top: '-100px'}} clearTags={this.props.clearTags} reshuffleDeck = {this.props.reshuffleDeck}  resetDisliked = {this.resetDisliked} disliked = {this.state.disliked}/> 
                        }
                        <Card 
                            top="0px" 
                            spotlight = {this.props.spotlight}  
                            likeButtonPressed = {this.likeButtonPressed} 
                            dislikeButtonPressed = {this.dislikeButtonPressed} 
                            currentClubInfo={this.state.currentClubInfo} 
                            expandClub={this.props.expandClub} 
                            color = {this.props.color}
                        />
                        <Reactions 
                            animate={this.state.animate} 
                            currentClubInfo={this.state.currentClubInfo} 
                            likeCallBack={this.likeButtonPressed} 
                            dislikeCallBack={this.dislikeButtonPressed} 
                            reverseCallBack={this.reverseButtonPressed} 
                        /> 
                    </div>
                } 
                {
                    (this.state.confirmation == null) ? 
                    ""
                    :
                    <ConfirmationThrow state={this.state.confirmation} close={() => this.setState({confirmation: null})} />
                }   
            </Box>
            )
        }
        else {
            var height;
            var top;
            var top2;
            var divHeight = "100%";
            if(window.innerHeight <= 725) {
                divHeight = "533px"
            }
            if(window.innerHeight <= 824) {
                height = "431px"
                top = "calc((100% - 504px) / 2)";
                top2 = "calc(((100% - 504px) / 2) + 10px)";
            }
            else {
                height = "510px"
                top = "calc((100% - 583px) / 2)";
                top2 = "calc(((100% - 583px) / 2) + 10px)";
            }
            return (
                <Box id={styles.cardView} style={{transition: ".8s", width: `calc(100% - ${this.props.width}px)`, height: {divHeight}}}>
                    <SwipeGridToggle 
                        swipe={true} 
                        color={this.props.color} 
                        changeView={this.props.changeView} 
                        openFiltersPopup = {this.props.openFiltersPopup}
                    />
                    {
                        this.state.done? 
                        <div id={styles.desktop_div}>
                            <NoMoreClubs 
                                card = {false} 
                                desktop={true} 
                                style = {{height: '100%', top: '0px'}} 
                                clearTags={this.props.clearTags} 
                                reshuffleDeck = {this.props.reshuffleDeck}  
                                resetDisliked = {this.resetDisliked} 
                                disliked = {this.state.disliked}
                                top = {top}
                                height = {height}
                            /> 

                        </div> 
                        : 
                        <div id={styles.desktop_div}>
                            { this.state.nextClubInfo ?
                            <Card 
                                top= {top2} 
                                spotlight = {this.props.spotlight} 
                                likeButtonPressed = {this.likeButtonPressed} 
                                dislikeButtonPressed = {this.dislikeButtonPressed} 
                                currentClubInfo={this.state.nextClubInfo} 
                                expandClub={this.props.expandClub} 
                                color = {this.props.color}
                                height = {height}
                            />
                            :
                            <NoMoreClubs
                                 desktop={true} 
                                 card = {true} 
                                 style = {{height: '100%', top: '0px'}}  
                                 style2 = {{top: '-80px'}} 
                                 clearTags={this.props.clearTags} 
                                 reshuffleDeck = {this.props.reshuffleDeck} 
                                 resetDisliked = {this.resetDisliked} 
                                 disliked = {this.state.disliked}
                                 top = {top2}
                                height = {height}
                            /> 

                        }
                            <Card 
                                top={top} 
                                spotlight = {this.props.spotlight} 
                                likeButtonPressed = {this.likeButtonPressed} 
                                dislikeButtonPressed = {this.dislikeButtonPressed} 
                                currentClubInfo={this.state.currentClubInfo} 
                                expandClub={this.props.expandClub} 
                                color = {this.props.color}
                                height = {height}
                            />
                            <Reactions animate={this.state.animate} currentClubInfo={this.state.currentClubInfo} likeCallBack={this.likeButtonPressed} dislikeCallBack={this.dislikeButtonPressed} reverseCallBack={this.reverseButtonPressed} /> 
                        </div>
                    }
                    {
                        (this.state.confirmation == null) ? 
                        ""
                        :
                        <ConfirmationThrow state={this.state.confirmation} close={() => this.setState({confirmation: null})} />
                    }
                    {
                        (this.props.requestFeatures)? 
                        <RequestFeatures />
                        :
                        ""
                    }

                </Box>
            );
        }
    }
}
export default CardView;
