import React from 'react';
import styles from './CarouselPrev.module.css';
import * as staticImages from '../../staticImages';

class CarouselPrev extends React.Component {
    render() {
        if (this.props.color) {
            return (
                <img src={staticImages.ARROW_BACKWARDS} alt = "backwards" className={styles.prev} />
            );
        }
        else {
            return (
                <img src={staticImages.ARROW_BACKWARDS_DISABLED} alt = "" className={styles.prev} />
            )
        }
    }
}
export default CarouselPrev;