import React from 'react';
import {Box} from 'grommet';

import styles from './FilterOption.module.css';

function FilterOption({index, tag, top, selected, addTag, removeTag, background, loadClubs, xIcon, arrow, query, removeQuery, color}){
    // if(!query || index !== 0){
    if(selected) {
        return (
            <Box className = {styles.outerBoxSelected} style = {{top: top, background: background}}  >
                <div className = {styles.text} style = {{color: '#ffffff'}}>
                {tag}

                </div>
                <img src = {xIcon} alt = "x" className = {styles.xIcon} onClick = {() => {removeTag(tag)}} />
            {/* <Box className = {styles.outerBoxSelected} style = {{top: top, background: background}} onClick = {() => {removeTag(tag); loadClubs()}} > */}
            </Box>
        )
    } else {
        return (
            <Box className = {styles.outerBoxUnselected} style = {{top: top}} onClick = {() => {addTag(tag)}} >
                <div className = {styles.text}>
                    {tag}
                </div>

                <img src = {arrow} alt = "x" className = {styles.arrow}  />

            {/* <Box className = {styles.outerBoxUnselected} style = {{top: top}} onClick = {() => {addTag(tag); loadClubs()}}> */}
            </Box>
        )
    // }} else {
    //     return (
    //         <Box className = {styles.outerBoxSelected} style = {{top: top, background: color}}  >
    //             <div className = {styles.text} style = {{color: '#ffffff'}}>
    //             "{tag}"

    //             </div>
    //             <img src = {xIcon} alt = "x" className = {styles.xIcon} onClick = {() => {removeQuery()}} />
    //         {/* <Box className = {styles.outerBoxSelected} style = {{top: top, background: background}} onClick = {() => {removeTag(tag); loadClubs()}} > */}
    //         </Box>
    //     )
     }
    
}

export default FilterOption;