import React from 'react';
import styles from './GridComponent.module.css';
import { Box } from 'grommet';

import GridCard from './GridCard';

function InnerCategoryGridComponent({clubs, thisId, color, token, expandClub, addToLocalMailingList, top}) {
    return (
        <Box animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}} id={thisId} style={{top: top, justifyContent: "center"}}>
            {
                clubs.map((club,index) => 
                    <GridCard 
                        key={index}
                        color={color}
                        info={club}
                        heart={club.favorited}
                        token={token}
                        expandClub={expandClub}
                        addToLocalMailingList = {addToLocalMailingList}
                    />
                )
            }
        </Box>
    );
}

class GridComponent extends React.Component {
    render() {
        let thisId;
        if (this.props.view === "mobile") {
            thisId = styles.grid_div_mobile;
        }
        else {
            thisId = styles.grid_div;
        }
        if (this.props.state === "category") {
            let temp = null;
            if (this.props.clubs[""] != undefined) {
                temp = this.props.clubs[""];
                delete this.props.clubs[""];
            }
            return (
                <div>
                    {
                        Object.keys(this.props.clubs).map((key, index) => (
                            <div key={index}>
                                <div className={styles.categoryHeader} style={{top: this.props.top}}>
                                    <div className={styles.categoryText}>{key}</div>
                                    <div className={styles.categoryNumDiv}>
                                        <div className={styles.categoryNum}>{this.props.clubs[key].length}</div>
                                    </div>
                                </div>
                                <InnerCategoryGridComponent
                                    clubs={this.props.clubs[key]}
                                    thisId={thisId}
                                    color={this.props.color}
                                    token={this.props.token}
                                    expandClub={this.props.expandClub}
                                    addToLocalMailingList={this.props.addToLocalMailingList}
                                    top={this.props.top}
                                />
                            </div>
                        ))
                    }
                    {
                        temp !== null ? 
                        <div>
                            <div className={styles.categoryHeader} style={{top: this.props.top}}>
                                <div className={styles.categoryText}></div>
                                <div className={styles.categoryNumDiv}>
                                    <div className={styles.categoryNum}>{temp.length}</div>
                                </div>
                            </div>
                            <InnerCategoryGridComponent
                                clubs={temp}
                                thisId={thisId}
                                color={this.props.color}
                                token={this.props.token}
                                expandClub={this.props.expandClub}
                                addToLocalMailingList={this.props.addToLocalMailingList}
                                top={this.props.top}
                            />
                        </div>
                        :
                        ""
                    }
                </div>
            );
        }
        else if (this.props.state === "A-Z") {
            return (
                <Box animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}} id={thisId} style = {{top: this.props.top}}>
                    {
                        this.props.clubs.map(
                            (club,index) => (
                                <GridCard 
                                    key={index}
                                    color={this.props.color}
                                    info={club}
                                    heart={club.favorited}
                                    token={this.props.token}
                                    expandClub={this.props.expandClub}
                                    addToLocalMailingList = {this.props.addToLocalMailingList}
                                />
                            )
                        )
                    }
                </Box>
            )
        }
        else if (this.props.state === "Z-A") {
            return (
                <Box animation={{"type": "fadeIn", "delay": 0, "duration": 2000, "size": 'xsmall'}} id={thisId} style = {{top: this.props.top}}>
                    {
                        this.props.clubs.slice(0).reverse().map(
                            (club,index) => (
                                <GridCard 
                                    key={index}
                                    color={this.props.color}
                                    info={club}
                                    heart={club.favorited}
                                    token={this.props.token}
                                    expandClub={this.props.expandClub}
                                    addToLocalMailingList = {this.props.addToLocalMailingList}
                                />
                            )
                        )
                    }
                </Box>
            )
        }
    }
}
export default GridComponent;