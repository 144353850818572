import React from 'react';
import styles from './MeetUs.module.css';
import * as staticImages from '../../staticImages';

class MeetUs extends React.Component {
    render() {
        return(
            <div id={styles.outer_div}>
                <div id={styles.meet_us_live}>MEET US LIVE</div>
                <img src={staticImages.BOLT} alt="bolt" id={styles.bolt}/>
            </div>
        );
    }
}

export default MeetUs;