import React from 'react';
import styles from './LoginButton.module.css';
import * as staticImages from '../../staticImages';

class LoginButton extends React.Component {
    render() {
        if (!this.props.loginState) {
            return (
                <div id={styles.google_button} style={{background: this.props.color}} onClick={() => this.props.login(this.props.loginState, this.props.authenticator)}>
                    <div id={styles.sign_in_text_full}>
                        Continue to Site
                    </div>
                </div>
            );
        }
        else if (this.props.authenticator === "google") {
            return (
                <div id={styles.google_div} onClick={() => {this.props.login(this.props.loginState, "google"); this.setState({noOrgsPopupCancelled: false, incognitoPopupCancelled: false})}}>
                    <div id={styles.google}>Sign in with Google</div>
                    <img id={styles.icons} alt="google" src={staticImages.GOOGLE}/>
                </div>
            );
        }
        else if (this.props.authenticator === "microsoft") {
            return (
                <div id={styles.microsoft_div} onClick={() => {this.props.login(this.props.loginState, "microsoft"); this.setState({noOrgsPopupCancelled: false, incognitoPopupCancelled: false})}}>
                    <div id={styles.google}>Sign in with Microsoft 365</div>
                    <img id={styles.icons} alt="office 365" src={staticImages.OFFICE365}/>
                </div>
            );
        }
    }
}
export default LoginButton;