import React from 'react';
import styles from './NoMoreClubs.module.css';
import * as staticImages from '../../staticImages';
import { Box } from 'grommet';
import * as firebase from 'firebase/app';


class NoMoreClubs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.clearFavorites = this.clearFavorites.bind(this);
    }

    clearFavorites(){
        firebase.auth().currentUser.getIdToken()
        .then((idToken) =>{
            fetch(
                'https://covid-response-tool.herokuapp.com/api/clearFavorites/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                this.props.reshuffleDeck();
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

  
    render() {
        // For Grid View
        if(this.props.grid === true) {
            if(window.innerWidth < 560) {
                return( 
                    <Box style={{width: "100%", top: "397px", left: "0px", position: "absolute"}}>
                        <img id={styles.image_mobile} src={staticImages.LOGIN_JOIN_OUTLINE} alt="Student browsing folder"/>
                        <div id={styles.no_more_mobile}>No Clubs Found</div>
                        {
                            (this.props.tags_selected.length === 0)?
                            <div></div>
                            :
                            <div id={styles.clear_box} onClick={this.props.clearTags}>
                                <div id={styles.clear_text}>Clear Filters</div>
                            </div>
                        }
                    </Box>
                );
            }
            return( 
                <Box style={{width: "508px", top: "397px", left: "calc(50% - 253px)", position: "absolute"}}>
                    <img id={styles.image} src={staticImages.LOGIN_JOIN_OUTLINE} alt="Student browsing folder"/>
                    <div id={styles.no_more}>No Clubs Found</div>
                    {
                        (this.props.tags_selected.length === 0)?
                        <div></div>
                        :
                        <div id={styles.clear_box} onClick={this.props.clearTags}>
                            <div id={styles.clear_text}>Clear Filters</div>
                        </div>
                    }
                </Box>
            );
        }
        else {
            let bottom = '30px'
            let height = window.innerHeight - 340 + 'px';
            let top = "0px";
            if (height > "550px") {
                top = (height - 550) / 2;
                height = "550px";
            }
            let width = '380px';
            let left = 'calc(50% - 190px)';
            
            if(this.props.card) {
                if(window.innerWidth >= 767){
                    top = this.props.top;
                } else {
                    height = 'calc(100% - 90px)';
                    top = "25px";
                }
            }
            if(window.innerWidth < 767) {
                height = 'calc(100% - 125px)';
                bottom = '0px'
                width = 'calc(100% - 40px)';
                left = '20px';
                top = "10px";
            }
            else {
                height = this.props.height;
                width = '375px';
                left = 'calc(50% - 190px)';
                top = this.props.top;
            }
            // if(window.innerWidth < 400) {
            //     width = '80%';
            //     left = '10%';
            // }

            
            return(
                <div>
                    {this.props.card ? 
                    <Box className = {styles.outer} style = {this.props.style}>
                        <div className = {styles.base} style={{top: top, height: height, minHeight: '389px', left: left, width: width}}>
                            <img className={styles.img} src={staticImages.NO_MORE_CLUBS} alt="Student browsing folder" />
                            <div className={styles.title}>No More Clubs</div>
                            {this.props.disliked ?
                                <div className={styles.description}>We've reached the end of the clubs to swipe through. Let's reshuffle your deck!</div>
                                :
                                <div className={styles.description}>You have liked all the clubs at your university. You can clear likes to swipe through more clubs!</div>
                            }
                            {this.props.disliked ?
                            <button className={styles.reshuffle}  onClick={()=> {this.props.reshuffleDeck(); this.props.resetDisliked()}} >
                                Reshuffle
                            </button> 
                            :
                            <button className={styles.reshuffle} onClick={this.clearFavorites} >
                                Clear Favorites
                            </button> 
                            }
                            
                        </div>
                    </Box>
                    :
                    <Box className = {styles.outer} style = {this.props.style}>
                        <div className = {styles.base} style={{top: top, height: height, left: left, width: width}}>
                            <img className={styles.img} src={staticImages.NO_MORE_CLUBS} alt="Student browsing folder" />
                            <div className={styles.title}>No More Clubs</div>
                            {this.props.disliked ?
                                <div className={styles.description}>We've reached the end of the clubs to swipe through. Let's reshuffle your deck!</div>
                                :
                                <div className={styles.description}>You have liked all the clubs at your university. You can clear likes to swipe through more clubs!</div>
                            }                            {this.props.disliked ?
                            <button className={styles.reshuffle} onClick={()=> {this.props.reshuffleDeck(); this.props.resetDisliked()}} >
                                Reshuffle
                            </button> 
                            :
                            <button className={styles.reshuffle} onClick={this.clearFavorites} >
                                Clear Favorites
                            </button> 
                            }
                        </div>
                    </Box>
                
                    }
                
                       
            
                    
                </div>
            );
        }
    }
}
export default NoMoreClubs;