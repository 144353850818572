export const APPLICATION = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-macbook_mail.svg?alt=media&token=cfbec8aa-2e17-4343-ab8e-7ed4c27255e1"
export const CALENDAR = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fcalendar.svg?alt=media&token=5b256989-10ae-4758-8a77-4aaf4a919e4d"
export const EXTERNAL = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FExternal.png?alt=media&token=4276d820-0c38-4e33-b8a4-f22c31c6cac8"
export const FACEBOOK = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FFacebook.png?alt=media&token=cc3d80bf-d355-4369-8230-baf753f0717e"
export const INSTAGRAM = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FInstagram.png?alt=media&token=48a20f4d-81ad-4a92-841b-f3e3aa6b85cb"
export const LINKEDIN = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-linkedin.svg?alt=media&token=2011daca-14ec-4435-bf03-97858d51254bF"
export const PATH_ARROW = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FPatharrow.png?alt=media&token=7c78ad0a-5516-41dd-b443-96849109264f"
export const TWITTER = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-twitter.svg?alt=media&token=ab2cfd68-d6ee-4eaa-82a7-58a91c215dde"
export const WEBSITE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-internet.svg?alt=media&token=d4107563-59e0-4795-b834-e5717cece0ab"
export const ARROW = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Farrow.svg?alt=media&token=5356a1b0-edf5-4890-9eb5-cd2048c73ffb"
export const ARROW_BACKWARDS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Farrow_backwards.png?alt=media&token=3e20c6cc-20b5-411c-83f6-6c8736edd486"
export const ARROW_BACKWARDS_DISABLED = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Farrow_disabled_backwards.png?alt=media&token=89d85ecf-fca1-4213-8ba2-56305838e1a2"
export const ARROW_FORWARDS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Farrow_forwards.png?alt=media&token=33d2b4d6-52fb-4568-b561-5b145691f8b1"
export const ARROW_FORWARDS_DISABLED = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Farrow_disabled_forwards.png?alt=media&token=f2283c34-4e5e-45ee-af78-47722415a82a"
export const BOLT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fbolt.svg?alt=media&token=06671c95-8978-4f9e-a4c2-15684d14064d"
export const CAL_GREY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fcal_gray.png?alt=media&token=0dfa1b48-a0fd-4c3e-ba69-06b9e30f42eb"
export const CAL_WHITE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fcalendar.svg?alt=media&token=6d970909-ec0a-423d-a0f2-d35bc85e5d80"
export const CHEVRON_LEFT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fexpand_white.svg?alt=media&token=390a4b5a-12d0-48a1-91b9-924434f165f9"
export const CLOSE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fclose.svg?alt=media&token=4213341d-7ba1-4c59-8655-7d15091e62a6"
export const DISLIKE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fthumbs_down.svg?alt=media&token=26811173-3686-45bd-9eb4-5244dd4d9f5a"
export const ELLIPSE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fellipse.png?alt=media&token=d90198fe-b31f-4bbc-ae27-869154e9e14d"
export const EMPTY_HEART_GREY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fempty_heart_black.svg?alt=media&token=0509ea9e-a728-4139-98d3-af8ea3111379"
export const EMPTY_HEART_WHITE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fempty_heart_white.svg?alt=media&token=dbc1926b-48bd-42ef-97d4-fe8919a8fb12"
export const EXIT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fclose.svg?alt=media&token=4213341d-7ba1-4c59-8655-7d15091e62a6"
export const FILLED_HEART = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FfilledHeart.png?alt=media&token=498887e9-47ef-4fd1-9edf-a1c2c41f3857"
export const GOOGLE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fgoogle.png?alt=media&token=7ebab1d9-0cfb-409e-8e13-b0b60b492541"
export const MICROSOFT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fmicrosoft.png?alt=media&token=2560e248-ab2b-4656-b531-d255cad620f9"
export const HEART_WHITE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-heart_white_filled.svg?alt=media&token=5c6b1fbd-0f0d-454d-834c-1392c4f1d6d9"
export const HIDE_BUTTON = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fhidebutton.png?alt=media&token=4ad57a9f-8413-4fbc-b32b-10c7f31b2a53"
export const ASCENDING_SORT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-ascending-sorting-96.png?alt=media&token=60f37687-7db7-46ac-891e-bd9e8f8ef4ed"
export const SEARCH = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-search-150.png?alt=media&token=d42c0627-cb65-4e44-bcff-e5ff5c7e5f02"
export const LIKE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Flike.png?alt=media&token=3b44811e-c1bf-46dc-94d8-563ca76142b5"
export const LOGIN_DISCOVER = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Flogin_discover.svg?alt=media&token=ba4a3f55-58e1-4d62-89b3-d322a1f58226"
export const LOGIN_EVENTS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Flogin_events.svg?alt=media&token=288401a8-4432-45ff-ab5e-032a66041fb0"
export const LOGIN_JOIN = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Flogin_join.svg?alt=media&token=3e735dec-a5f6-4da3-974c-af53d85a5329"
export const LOGIN_JOIN_OUTLINE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FNoClubs.svg?alt=media&token=9a230aa9-a89d-4713-b742-0c22e92f1537"
export const LOGOUT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Flogout.svg?alt=media&token=9a9f13a8-f169-4ac8-aeab-84f4f59c10cb"
export const MEMBERS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fmembers.png?alt=media&token=9236188c-6154-49d3-9129-6bfe1da63301"
export const MULTIPLY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fmultiply.png?alt=media&token=2b975f96-99dd-44e4-9f30-6600986e4067"
export const QUOTE_GREY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fquote_grey.svg?alt=media&token=9d17c287-d659-4505-a025-fe1bfbb20791"
export const QUOTE_BLACK = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fquote_black.svg?alt=media&token=01d5ffe1-a7af-467b-b523-6055c81ead77"
export const REPLAY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Freplay.svg?alt=media&token=8e130c77-2c43-499a-b9ba-05a6aaca394f"
export const SEND_GREY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fsend_grey.svg?alt=media&token=89edde2b-f123-41c8-8703-4c235641bd39"
export const SOCIAL = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fsocial.png?alt=media&token=708eeaab-256f-4a82-853b-9bb9ddf5b44c"
export const TIME = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftime.png?alt=media&token=1d6ae30b-8030-4dd1-abf9-c1d424e4c3f1"
export const TOGGLE_ARROW = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftoggle_arrow.png?alt=media&token=f79548d1-fdba-4564-8776-0f29f62fbf7c"
export const TOGGLE_DOT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftoggle_dot.png?alt=media&token=c3b718c4-3697-4f94-9789-c0b07d225464"
export const TOGGLE_RECTANGLE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftoggle_rectangle.png?alt=media&token=98c7282c-4f64-44e1-9bee-fd67be7690da"
export const TWITTER_OUTLINE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftwitter.png?alt=media&token=d6b5e8df-5623-44d3-a567-e80a0c88f8c2"
export const UNDO = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fundo.png?alt=media&token=befeb5ed-bbbf-4a0d-9b83-97af7a5eea44"
export const VERTICAL_BAR = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FverticalBar.png?alt=media&token=c8a6669c-0ff9-4de3-8f8d-4bea94249e5c"
export const FILTER = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ffilter.png?alt=media&token=e953bcd0-9caa-425e-a5eb-ac4e2605e129"
export const ZOOM = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fzoom.svg?alt=media&token=544223e1-ea3f-40c3-8627-c92d5526a5ac"
export const MEET = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fgoogle_meet.svg?alt=media&token=c02aadbe-2474-48eb-b5ae-07872d7b347b"
export const SKYPE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fskype.svg?alt=media&token=e0452b4f-f643-4616-8955-a0da106ec6bb"
export const TEAMS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fmicrosoft_teams.svg?alt=media&token=d6f29ea6-b0bf-40ff-aa2f-6f006293b2ed"
export const BLUEJEANS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fbluejeans.svg?alt=media&token=b6d40af4-cbce-45a2-9df0-1b8967ed506d"
export const GUEST = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-person-64.png?alt=media&token=4b33bfa4-6bef-482e-a3c2-df4109be3124"
export const SUBSCRIBE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-subscription%202.svg?alt=media&token=bde498a4-3f6d-41ec-81a3-7d20acff676e"
export const SUBSCRIBED = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ficons8-check_inbox%201.svg?alt=media&token=8aaec7a3-d46d-4881-a245-dd5ce2023c15"
export const NO_EVENTS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fno_events.svg?alt=media&token=2e70c94b-313c-4534-974f-1030616c0d9a"
export const NEW_CAL = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fnew_cal.svg?alt=media&token=908ea933-1995-4263-8348-41c1fa848a24"
export const CONFIRMATION_LIKE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FconfirmationLike.svg?alt=media&token=499b96dd-466e-4241-a037-f452d859bb77"
export const CONFIRMATION_REPLAY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FconfirmationReplay.svg?alt=media&token=d8cfed71-8986-4938-a752-1be9b15afde2"
export const CONFIRMATION_PASS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FconfirmationPass.svg?alt=media&token=a2f4b88c-6ffd-4a0a-922d-4367e9ba81a4"
export const LOGIN_IMAGES = {
    umich: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2Fumich.edu_login.jpg?alt=media&token=a25615c7-6cf1-46d5-9ba8-e5a662d6eea7",
    demo: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2Fdemo_login.png?alt=media&token=07c45b16-d456-401d-b5d8-413c4afacc07",
    usc: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2FUSC_login.jpg?alt=media&token=a6df8809-12ba-4fe4-8aa4-02238376f20d",
    union: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2Funion.edu_login.jpg?alt=media&token=a9a31f10-c826-49c1-923f-edc1598595ce",
    psu: "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/UniversityLogos%2Fpsu.edu_login.jpg?alt=media&token=e94da9c9-da7f-478c-9605-7b680fa437fe"
}
export const FALLBACK = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ffallback.png?alt=media&token=86650458-f532-4805-a8d8-ae5761d338db"
export const NO_MORE_CLUBS = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fno_more_clubs.svg?alt=media&token=21f6bc2e-cbc8-48cd-8477-7a193e42a582"
export const OFFICE365 = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Foffice_365.svg?alt=media&token=db46d440-3ebb-4d56-a36a-57f5b7f39853"
export const AtoZGrey = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FA-Z.svg?alt=media&token=a6e737af-90bb-4dc3-8368-6b2e08d940c6"
export const categorySort = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FcategorySort.svg?alt=media&token=40fcabc2-3537-40a4-b52c-5178234cbd43"
export const categorySortGrey = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FcategorySort_grey.svg?alt=media&token=278eca86-f326-4f72-ae0d-cb5c319c1751"
export const ZtoAGrey = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FZ-A.svg?alt=media&token=a4448444-7b2f-41b1-bde4-2023fa71cd37"
export const AtoZ = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FA-ZDark.svg?alt=media&token=1c30d272-f977-4890-bd64-0a98c2fe47f1"
export const ZtoA = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FZ-ADark.svg?alt=media&token=a20a47eb-a57b-4300-91f3-947e02b0212b"
export const filterSort = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ffilter_and_sort.svg?alt=media&token=38ec2253-37cc-47c9-9c2e-1d1d1f190abe"
export const LEAVE = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fleave.svg?alt=media&token=9be15770-40e2-4826-b3f0-b34485ad3852"
export const EXPAND = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fexpand.svg?alt=media&token=90bb8aca-6a59-4879-89c4-1997f93b5c4c"
export const CALENDLY = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fcalendly_button.svg?alt=media&token=c3752ec4-3533-471e-b87f-2efcaf23021e"
export const WHITE_ARROW = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FPath.svg?alt=media&token=e4eb464b-2079-4f66-b524-5d13ec9d626f"
export const GRAY_EXIT = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fgray_exit.svg?alt=media&token=669167a2-adcb-4ecc-ae70-038fe2dd50d9"
export const REQUEST = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Frequest_button.svg?alt=media&token=fc21a246-dbea-4f6b-8aa9-3035b319c23e"
export const LOGIN = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fleader_login.png?alt=media&token=4df03ab6-52a6-4a77-b6af-ac53b165da5e"
export const OPEN_DESKTOP = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Ftoggle_fullscreen.png?alt=media&token=be72c1f2-27ad-4d6a-a772-e2065fbe10ae"
export const UNIFLOW = "https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2FUniflow_login.png?alt=media&token=ad20b403-1fdb-43ec-ac67-eba6518d946b"