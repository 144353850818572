import React from 'react';
import {  Box} from 'grommet';
import styles from './GridView.module.css';
import * as firebase from 'firebase/app';

import SwipeGridToggle from './SwipeGridToggle';
import GridCard from './GridCard';
import Filters from './Filters';
import NoMoreClubs from './NoMoreClubs';
import EventBanner from './EventBanner';
import GridComponent from './GridComponent';
import RequestFeatures from './RequestFeatures';

class GridView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventUUID: null,
            updated: false,
            favoritesSize: "off", 
            clubPageSize: "off",
            query: this.props.query,
            clubs: [],
            hasClubs: true,
            dropdownState: "A-Z"
        }
        this.loadClubs = this.loadClubs.bind(this);
        this.unfavorite = this.unfavorite.bind(this);
        this.checkRefetchClubs = this.checkRefetchClubs.bind(this);
        this.addToLocalMailingList = this.addToLocalMailingList.bind(this);
        this.changeDropdown = this.changeDropdown.bind(this);
    }

    componentDidMount() {
        this.loadClubs();
    }

    loadClubs(dropdownState, callback) {
        let realDropdownState = dropdownState;
        if (realDropdownState == undefined) {
            dropdownState = this.state.dropdownState
        }
        firebase.auth().currentUser.getIdToken()
        .then((idToken) => {
            fetch(
                'https://covid-response-tool.herokuapp.com/api/getFilteredClubs/', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: idToken,
                        tags: this.props.tags_selected,
                        eventUUID: this.props.eventUUID,
                        query: this.props.query,
                        categories: (dropdownState === "category")
                    }),
                }
            )
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
                
            })
            .then((data) => {
                if(data.length === 0 || Object.keys(data).length === 0) {
                    this.setState({
                        hasClubs: false,
                        clubs: data,  
                        eventUUID: this.props.eventUUID,  
                        query: this.props.query, 
                        dropdownState: dropdownState != undefined ? dropdownState : this.state.dropdownState      
                    });
                }
                else {
                    this.setState({
                        hasClubs: true,
                        clubs: data,  
                        eventUUID: this.props.eventUUID,  
                        query: this.props.query, 
                        dropdownState: dropdownState != undefined ? dropdownState : this.state.dropdownState           
                    });
                }
                if (callback != undefined) {
                    callback();
                }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }    

    unfavorite() {
        for (let i = 0; i < this.state.clubs.length; i++) {
            if (this.state.clubs[i].uuid === this.props.unfavoritedClub) {
                this.state.clubs[i].heart = false;
                break;
            }
        }
        this.props.resetUnfavorite();
        this.setState ({
            updated: true
        });
        this.forceUpdate();
    }

    checkRefetchClubs() {
        if((this.props.eventUUID !== this.state.eventUUID) || this.state.query !== this.props.query) {
            this.setState({
                clubs: [],
                eventUUID: this.props.eventUUID,  
                query: this.props.query,
            });
            this.loadClubs();
        }
        if ((this.props.clubPageWindowSize === "off" && this.state.clubPageSize !== "off") ||
            (this.props.favoritesWindowSize === "off" && this.state.favoritesSize !== "off")) {
                this.state.clubPageSize = this.props.clubPageWindowSize;
                this.state.favoritesSize = this.props.favoritesWindowSize;
                this.loadClubs();
        }
        else if((this.state.favoritesSize === "off" && this.props.favoritesWindowSize !== "off")) {
            this.state.favoritesSize = this.props.favoritesWindowSize;
            this.loadClubs();
        }
        else if (this.state.clubPageSize !== this.props.clubPageWindowSize || this.state.favoritesSize !== this.props.favoritesWindowSize) {
            this.state.clubPageSize = this.props.clubPageWindowSize;
            this.state.favoritesSize = this.props.favoritesWindowSize;
        }
    }

    addToLocalMailingList(action, clubUUID, userUUID, email) {
        console.log("add to local mailing list", action, this.state.dropdownState);
        if (action === "join") {
            if (this.state.dropdownState !== "category") {
                for (let i = 0; i < this.state.clubs.length; i++) {
                    if (this.state.clubs[i].uuid === clubUUID) {
                        let clubs = this.state.clubs;
                        clubs[i].mailingList[userUUID] = email;
                        this.setState({clubs: clubs});
                        break;
                    }
                }
            }
            else {
                let tags = Object.keys(this.state.clubs);
                for (let i = 0; i < tags.length; i++) {
                    for (let j = 0; j < this.state.clubs[tags[i]].length; j++) {
                        if (this.state.clubs[tags[i]][j].uuid === clubUUID) {
                            let clubs = this.state.clubs;
                            clubs[tags[i]][j].mailingList[userUUID] = email;
                            this.setState({clubs: clubs});
                            break;
                        }
                    }
                }
            }
        }
        else {
            if (this.state.dropdownState !== "category") {
                for (let i = 0; i < this.state.clubs.length; i++) {
                    if (this.state.clubs[i].uuid === clubUUID) {
                        let clubs = this.state.clubs;
                        delete clubs[i].mailingList[userUUID];
                        this.setState({clubs: clubs});
                        break;
                    }
                }
            }
            else {
                let tags = Object.keys(this.state.clubs);
                for (let i = 0; i < tags.length; i++) {
                    for (let j = 0; j < this.state.clubs[tags[i]].length; j++) {
                        if (this.state.clubs[tags[i]][j].uuid === clubUUID) {
                            let clubs = this.state.clubs;
                            delete clubs[tags[i]][j].mailingList[userUUID];
                            this.setState({clubs: clubs});
                            break;
                        }
                    }
                }
            }
        }
    }

    changeDropdown(state) {
        if (this.state.dropdownState === "category" || state === "category") {
            this.loadClubs(state, () => this.props.changeDropdown(state));
        }
        else {
            this.props.changeDropdown(state);
            this.setState({dropdownState: state})
        }
    }

    render() {
        let top = '300px';
        let topMobile = '220px'
        
        if (this.state.dropdownState === "category") {
            topMobile = "235px"
        }
        if((this.props.eventSelected === -1 || (!this.props.eventSelected && this.props.eventSelected !== 0))){
            top = '229px';
            topMobile = '103px'
            if (this.state.dropdownState === "category") {
                topMobile = "120px"
            }
        }
        if(this.props.query) {
            top = '280px';
        }
        this.checkRefetchClubs();
        if(this.props.reload) {
            this.loadClubs();
            this.props.loaded();
        }
        
        if (window.innerWidth <= 767 && this.state.width !== 0){
        
            if (this.props.dropdownState !== this.state.dropdownState) {
                this.changeDropdown(this.props.dropdownState);
            }
            if(this.props.width !== 62) {
                return(
                    <div></div>
                );
            }
            else {
                return (
                    <Box style={{top: "0px", right: "0px", position: "absolute", width: "100%"}}>
                        {
                            (this.props.eventSelected === -1 || (!this.props.eventSelected && this.props.eventSelected !== 0)) ?
                            <SwipeGridToggle 
                                color={this.props.color} 
                                changeView={this.props.changeView} 
                                swipe={false}
                                openFiltersPopup = {this.props.openFiltersPopup}
                                setSearchQuery={this.props.setSearchQuery} 
                                query={this.props.query}
                            />
                            :
                            <EventBanner
                                event = {this.props.events[this.props.eventSelected]}
                                changeEvent={this.props.changeEvent} 
                                eventIndex = {this.props.eventSelected}
                                setSearchQuery={this.props.setSearchQuery} 
                                query = {this.props.query}
                                timezone = {this.props.timezone}
                            />
                            }
                            {(this.state.hasClubs)?
                            <GridComponent 
                                top={topMobile} 
                                clubs={this.state.clubs} 
                                state={this.state.dropdownState} 
                                view="mobile" 
                                color = {this.props.color}
                                token = {this.props.token}
                                expandClub = {this.props.expandClub}
                                addToLocalMailingList = {this.addToLocalMailingList}
                            />
                            :
                            <NoMoreClubs clearTags={this.props.clearTags} tags_selected={this.props.tags_selected} grid={true}/>
                        }
                    </Box>
                );
            }
        }
        return (
            <Box style={{transition: ".8s",top: "0px", right: "0px", position: "absolute", width: `calc(100% - ${this.props.width}px)`, background: "#F4F4F4"}}>
                {
                    ((this.props.eventSelected === -1 || (!this.props.eventSelected && this.props.eventSelected !== 0)) && !this.props.query) ?
                    <SwipeGridToggle 
                        color={this.props.color} 
                        changeView={this.props.changeView} 
                        swipe={false}
                        openFiltersPopup = {this.props.openFiltersPopup}
                    />
                    :
                    <EventBanner
                        event = {this.props.events[this.props.eventSelected]}
                        changeEvent={this.props.changeEvent} 
                        eventIndex = {this.props.eventSelected}
                        query = {this.props.query}
                        setSearchQuery={this.props.setSearchQuery} 
                        timezone = {this.props.universityData.timezone}
                    />
                }
                {
                    (this.state.hasClubs)?
                    <GridComponent 
                        top={top} 
                        clubs={this.state.clubs} 
                        state={this.state.dropdownState} 
                        view="desktop" 
                        color = {this.props.color}
                        token = {this.props.token}
                        expandClub = {this.props.expandClub}
                        addToLocalMailingList = {this.addToLocalMailingList}
                    />
                    :
                    <NoMoreClubs clearTags={this.props.clearTags} tags_selected={this.props.tags_selected} grid={true}/>
                    
                }
                {(this.props.eventSelected === -1 || (!this.props.eventSelected && this.props.eventSelected !== 0)) ?
                    <Filters 
                        tags = {this.props.tags}
                        tags_selected = {this.props.tags_selected}
                        removeTag = {this.props.removeTag}
                        addTag = {this.props.addTag}
                        color = {this.props.color}
                        loadClubs = {this.loadClubs}
                        clearTags = {this.props.clearTags}
                        openFiltersPopup = {this.props.openFiltersPopup}
                        query = {this.props.query}
                        removeQuery = {this.props.removeQuery}
                        dropdownState={this.state.dropdownState}
                        changeDropdown={this.changeDropdown}
                    />
                    :
                    <Filters 
                        tags = {this.props.tags}
                        tags_selected = {this.props.tags_selected}
                        removeTag = {this.props.removeTag}
                        addTag = {this.props.addTag}
                        color = {this.props.color}
                        loadClubs = {this.loadClubs}
                        event = {true}
                        clearTags = {this.props.clearTags}
                        openFiltersPopup = {this.props.openFiltersPopup}
                        query = {this.props.query}
                        removeQuery = {this.props.removeQuery}
                        dropdownState={this.state.dropdownState}
                        changeDropdown={this.changeDropdown}
                    />
                }
                {
                (this.props.requestFeatures)? 
                    <RequestFeatures />
                :
                    ""
                }
            </Box>
        );
    }
}
export default GridView;
