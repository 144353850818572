import React from 'react';
import styles from './Logo.module.css';
import { Box, Image} from 'grommet';


function Logo({logo, left, small, clearEverything}){
    if(small) {
        return(
            <Box className = {styles.outerBox} onClick = {()=> clearEverything()}>
                <Image fit="contain" margin = "small" src={logo} style = {{marginTop: '15px', marginLeft: '25px', cursor: 'pointer'}}/>

                {/* <img src = {logo} className = {styles.logo} alt = "logo" style = {{marginLeft: left, height: '50%', top: '29px'}}/> */}
            </Box>
        )
    } else {
        return(
            <Box className = {styles.outerBox} >
                <Image fit="contain" margin = "small" src={logo} style = {{marginTop: '20px', cursor: 'pointer'}} onClick = {()=> clearEverything()}/>

                {/* <img src = {logo} className = {styles.logo} alt = "logo" style = {{marginLeft: left}}/> */}
            </Box>
        )
    }
    
}

export default Logo;