import React from 'react';
import styles from './Hide.module.css';
import * as staticImages from '../../staticImages';

class Hide extends React.Component {
    render() {
        return(
            <div id={styles.hide_div} onClick={this.props.hideSpotlight}>
                <div id={styles.hide}>HIDE</div>
                <img id={styles.logout} alt="hide button" src={staticImages.HIDE_BUTTON} />
            </div>
        );
    }
}

export default Hide;