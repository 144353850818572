import React from 'react';
import styles from './Filters.module.css';
import * as staticImages from '../../staticImages';


import {Box} from 'grommet';

import FilterBox from './FilterBox';
import DropdownItem from './DropdownItem';

import * as firebase from 'firebase/app';

import { db } from '../../index.js';
//import onClickOutside from 'react-onclickoutside';



class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.tags,
            tags_selected: this.props.tags_selected,
            xIcon: staticImages.CLOSE,
            sort: staticImages.ASCENDING_SORT,
            colors: [
                `linear-gradient(90deg, #1488CC 0%, #2B32B2 100%)`,
                `linear-gradient(90deg, #BD7AE3 0%, #A811DA 100%)`,
                `linear-gradient(90deg, #606C88 0%, #3F4C6B 100%)`,
                `linear-gradient(90deg, #232526 0%, #595B5E 100%)`

            ],
            totalPrint: [],
            left: 0,
            dropdownOpen: false
        };
        this.removeQueryList = this.removeQueryList.bind(this);
    
    }

    logFilterClick = () => {
        const date = new Date();
        try {
            const userID = firebase.auth().currentUser.uid;
            window.ga('send', 'event', 'Filter Interaction', 'Clicked Add Filter');
            db.collection('logged_events').add({
                'category': 'Filter Interaction',
                'action': 'Clicked Add Filter',
                'time': date,
                'user': userID,
                'url': window.location.href,
            });
        } catch (e) {
            db.collection("logged_errors").add({
                'action': 'Clicked Add Filter',
                'time': date,
                'url': window.location.href,
            });
        }
    }


    removeQueryList(totalPrint) {
        // totalPrint.pop();
        // this.forceUpdate();
    }

    render(){
        let top = '125px';
        if(this.props.query) {
            top = '170px';
        }else {
            if(this.props.event) {
                top = '205px'
            }
        }
        let totalPrint = this.props.tags_selected.slice();
        // if(this.props.query && totalPrint.indexOf(this.props.query) === -1){
        //     totalPrint.unshift(this.props.query);
        // }
        if(totalPrint.length !== 0) {
            totalPrint.push("CLEAR");
        }
        let left = [];
        for(let i = 0; i < totalPrint.length; ++i){
            let add = 0;
            
            if(i !== 0) {
                add += totalPrint[i-1].length * 5 + 40;
                add += 10;
                add += left[i-1];
            }
            left.push(add);
        }
        let options = [];
        if (this.state.dropdownOpen) {
            if (this.props.dropdownState === "category") {
                options = ["A-Z", "Z-A"];
            }
            else if (this.props.dropdownState === "A-Z") {
                options = ["Z-A", "category"];
            }
            else if (this.props.dropdownState === "Z-A") {
                options = ["A-Z", "category"];
            }
        }
       return(
            <Box className = {styles.outerBox} style = {{top: top}}>
                <Box className = {styles.line}/>
                <div className={styles.dropdownOutline}>
                    <DropdownItem type={this.props.dropdownState} onClick={() => this.setState({dropdownOpen: !this.state.dropdownOpen})} state="title" dropdownOpen={this.state.dropdownOpen} />
                    {
                        (this.state.dropdownOpen) ? 
                        <div>
                            <DropdownItem type={options[0]} onClick={() => {
                                this.props.changeDropdown(options[0]);
                                this.setState({dropdownOpen: !this.state.dropdownOpen});
                            }} state="menu" />
                            <DropdownItem type={options[1]} onClick={() => {
                                this.props.changeDropdown(options[1]);
                                this.setState({dropdownOpen: !this.state.dropdownOpen});
                            }} state="menu" />
                        </div>
                        :
                        ""
                    }
                </div>
                {/* <DropButton
                    label="drop button"
                    dropAlign={{top: 'bottom', left: 'left'}}
                    dropContent={
                        <Box pad="large" background="light" />
                    }
                    className={styles.sort_options}
                    style={{border: "none", borderRadius: "6px", background: "white"}}
                /> */}
                {/* {totalPrint.length === 0 ?
                    <Box className = {styles.triggerButton} onClick = {this.props.openFiltersPopup}>
                     <div className = {styles.triggerButtonText}>
                        <img src = {staticImages.FILTER} alt = "filter" className = {styles.filterIcon}/>

                         ADD
                     </div>
                    </Box>
                    :
                    <Box className = {styles.triggerButton} onClick = {this.props.clearTags}>
                     <div className = {styles.triggerButtonText}>
                        <img src = {staticImages.FILTER} alt = "filter" className = {styles.filterIcon}/>

                         CLEAR
                     </div>
                    </Box>
                } */}

                
                
               
                {/* <FilterPopup
                    tags = {this.state.tags}
                    addTag = {this.props.addTag}
                    removeTag = {this.props.removeTag}
                    tags_selected = {this.props.tags_selected}
                    colors = {this.state.colors}
                    loadClubs = {this.props.loadClubs}
                    xIcon = {this.state.xIcon}
                /> */}
                {/* <div className = {styles.outerDiv}> */}
                <Box className = {styles.repeatGrid} direction = "row" style = {{maxWidth: '100000px'}}>
                {
                    totalPrint.map(
                        (tag,index) => (
                        <FilterBox 
                            index = {index}
                            tag = {tag}
                            // left = {`${135*index}px`}
                            background = {this.state.colors[this.props.tags.indexOf(tag)%4]}
                            width = {`${tag.length * 5 + 40}px`}

                            // style = {{ background:  this.state.colors[this.props.tags.indexOf(tag)%4], left: `${135*index}px`}}
                            // left = {`${index * 135}px`}
                            left = {left[index]}
                            removeTag = {this.props.removeTag}
                            loadClubs = {this.props.loadClubs}
                            xIcon = {this.state.xIcon}
                            // query = {this.props.query}
                            removeQuery = {this.props.removeQuery}
                            removeQueryList = {this.removeQueryList}
                            totalPrint = {totalPrint}
                            color = {this.props.color}
                            key = {index}
                            length = {totalPrint.length}
                            clearTags={this.props.clearTags}
                        />
                        )
                    )
                    }
                </Box>


                {totalPrint.length === 0 ?
                    <div id = {styles.filterTrigger} onClick = {() => {this.logFilterClick(); this.props.openFiltersPopup();}} style = {{background: this.props.color}}>
                        <img src = {staticImages.FILTER} alt = "filter" id = {styles.filterTriggerIcon}/>
                        <div id = {styles.addFilter} >
                            ADD FILTER

                        </div>
                    </div>
                    :
                    <div id = {styles.filterTrigger} onClick = {this.props.openFiltersPopup} style = {{width: '30px', background: this.props.color}}> 
                        <img src = {staticImages.FILTER} alt = "filter" id = {styles.filterTriggerIcon}/>
                    </div>
                }
                {/* {totalPrint.length === 0 ?
                    <Box className = {styles.triggerButton} onClick = {this.props.openFiltersPopup} style = {{left: `${135*totalPrint.length}px`}}>
                        <div className = {styles.triggerButtonText}>
                            ADD
                        </div>
                        <img src = {staticImages.FILTER} className = {styles.filterIcon}/>
                    </Box>
                    :
                    <Box className = {styles.triggerButton} onClick = {this.props.clearTags} style = {{left: `${135*totalPrint.length}px`}}>
                        <div className = {styles.triggerButtonText}>
                            CLEAR
                        </div>
                        <img src = {staticImages.FILTER} className = {styles.filterIcon}/>
                    </Box>
                } */}
            
                {/* </div> */}
                {/*<div className = {styles.sort}>
                    Sort Accending A-Z
                </div>
                <img src = {this.state.sort} alt = "sort" className = {styles.sortIcon}/>
                */}
            
            </Box>
       )
    }
}
export default Filters;