import React from 'react';
import styles from './Spotlight.module.css';
import { Box,} from 'grommet';
import * as staticImages from '../../staticImages';

import EventCard from './EventCard';
import Hide from './Hide';
import ReactTooltip from 'react-tooltip';

class Spotlight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.openClubEvent = this.openClubEvent.bind(this);
    }

    openClubEvent(index) {
        this.props.openClubPage("on", this.props.todaysEvents[index].orgInfo, this.props.todaysEvents[index].orgInfo.favorited);
    }

    render() {
        if(this.props.width === 62) {
            if (window.innerWidth <= 767) {
                return(
                    <Box id={styles.hidden_mobile} style={{background: this.props.color,}}>
                        <img id={styles.mobile_logo} alt="secondary logo" src={this.props.logos[1]} />
                        <div id={styles.mobile_open} onClick={this.props.hideSpotlight}>SPOTLIGHT</div>
                    </Box>
                );
            }
            else {
                return(
                    <Box id={styles.unhide_button} style={{background: this.props.color, width: `${this.props.width}px`}}>
                        <Box id={styles.spotlight_text}>S P O T L I G H T</Box>
                        <Box id={styles.pointer_div} onClick={this.props.hideSpotlight}>
                            <img id={styles.pointer}  src={staticImages.CHEVRON_LEFT} alt="" />
                        </Box>
                    </Box>
                )
            }
        }
        else {
            if (window.innerWidth <= 767) {
                return(
                    <Box id={styles.mobile_outer} style={{background: this.props.color,}}>
                        
                        <div id={styles.mobile_spotlight}>Spotlight</div>
                        <img id={styles.exit} onClick={this.props.hideSpotlight} alt="exit" src="https://firebasestorage.googleapis.com/v0/b/uniflow-events.appspot.com/o/Static%2Fmultiply-white.png?alt=media&token=b61eb938-0e07-48d2-9582-87965ec14174"/>
                        <Box id={styles.event_card_mobile}>
                        {
                            (this.props.events.length !== 0) ? 
                            <div id={styles.logo_div} style={{width: "100%"}}>
                                <img id={styles.logo} alt="secondary logo" src={this.props.logos[1]} />
                            </div>
                            :
                            ""
                        }
                        {
                            this.props.events.map(
                                (event,index) => (
                                    <div>
                                    <div data-tip data-for="eventTip1" key={index}>
                                    {
                                        (index === this.props.selected)?
                                        <EventCard 
                                            selected = {true}
                                            index = {index}
                                            event = {event}
                                            start = {event.time[0]}
                                            end = {event.time[1]}
                                            changeEvent = {this.props.changeEvent}
                                            timezone = {this.props.timezone}
                                            images ={event.images}
                                        />
                                        :
                                        <EventCard 
                                            selected = {false}
                                            index = {index}
                                            event = {event}
                                            start = {event.time[0]}
                                            end = {event.time[1]}
                                            changeEvent = {this.props.changeEvent}
                                            timezone = {this.props.timezone}
                                            images ={event.images}
                                        />
                                    }
                                    </div>
                                    <ReactTooltip id="eventTip1" className={styles.eventTip} place="right" effect="solid" delayShow={1000}>Click to explore event</ReactTooltip>
                                    </div>
                                ))
                                
                            }
                            {
                                (this.props.todaysEvents.length !== 0)?
                                    <div id={styles.logo_div} style={{width: "100%"}}>
                                        <div id={styles.todays}>Today’s Club Events</div>
                                    </div>
                                :
                                ""
                            }
                            {
                                this.props.todaysEvents.map(
                                    (item,index) => (
                                    <div>
                                    <div data-tip data-for="eventTip2" key={index}>
                                        <EventCard 
                                            selected = {false}
                                            index = {index}
                                            event = {item.event}
                                            start = {item.event.time[0]}
                                            end = {item.event.time[1]}
                                            changeEvent = {this.openClubEvent}
                                            timezone = {this.props.timezone}
                                            images = 
                                            {(item.orgInfo.logo === undefined)?
                                                [item.orgInfo.album[0]]
                                            :
                                                [item.orgInfo.logo]
                                            }
                                        />
                                    </div>
                                    <ReactTooltip id="eventTip2" className={styles.eventTip} place="right" effect="solid" delayShow={1000}>Click to explore event</ReactTooltip>
                                    </div>
                                    )
                                )
                            }
                        </Box>
                    </Box>
                );
            }
            else {
                return (
                    <Box  id={styles.wrapper_div} style={{background:this.props.color, width: `${this.props.width}px`,}}>
                        <div id={styles.spotlight}>Spotlight</div>
                        <Hide hideSpotlight = {this.props.hideSpotlight} />
                        <Box id={styles.event_card_div}>
                            {
                            (this.props.events.length !== 0) ? 
                            <div id={styles.logo_div} style={{width: "100%"}}>
                                <img id={styles.logo} alt="secondary logo" src={this.props.logos[1]} />
                            </div>
                            :
                            ""
                        }
                            {
                            this.props.events.map(
                                (event,index) => (
                                    <div>
                                    <div data-tip data-for="eventTip1" key={index} style={{width: "calc(100% - 20px)"}}>
                                    {
                                        (index === this.props.selected)?
                                        <EventCard 
                                            selected = {true}
                                            index = {index}
                                            event = {event}
                                            start = {event.time[0]}
                                            end = {event.time[1]}
                                            changeEvent = {this.props.changeEvent}
                                            timezone = {this.props.timezone}
                                            images ={event.images}
                                            description = {event.description}
                                        />
                                        :
                                        <EventCard 
                                            selected = {false}
                                            index = {index}
                                            event = {event}
                                            start = {event.time[0]}
                                            end = {event.time[1]}
                                            changeEvent = {this.props.changeEvent}
                                            timezone = {this.props.timezone}
                                            images ={event.images}
                                            description = {event.description}
                                        />
                                    }
                                    </div>
                                    <ReactTooltip id="eventTip1" className={styles.eventTip} place="right" effect="solid" delayShow={1000}>Click to explore event</ReactTooltip>
                                    </div>
                                ))
                            }
                            {
                                (this.props.todaysEvents.length !== 0)?
                                    <div id={styles.logo_div}>
                                        <div id={styles.todays}>Today’s Club Events</div>
                                    </div>
                                :
                                ""
                            }
                            {
                                this.props.todaysEvents.map(
                                    (item,index) => (
                                    <div>
                                    <div data-tip data-for="eventTip2" key={index}>
                                        <EventCard 
                                            selected = {false}
                                            index = {index}
                                            event = {item.event}
                                            start = {item.event.time[0]}
                                            end = {item.event.time[1]}
                                            changeEvent = {this.openClubEvent}
                                            timezone = {this.props.timezone}
                                            description = {item.orgInfo.name}
                                            images = 
                                            {(item.orgInfo.logo === undefined)?
                                                [item.orgInfo.album[0]]
                                            :
                                                [item.orgInfo.logo]
                                            }
                                        />
                                    </div>
                                    <ReactTooltip id="eventTip2" className={styles.eventTip} place="right" effect="solid" delayShow={1000}>Click to explore event</ReactTooltip>
                                    </div>
                                    )
                                )
                            }
                        </Box>
                    </Box>
                );
            }
        }
    }
}

export default Spotlight;